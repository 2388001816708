import React from 'react';
import { Collapse, Flex, IconButton, Text } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { StatusPoint } from '@src/components';
import { useDispatch, useSelector } from 'react-redux';
import { setCollapsed } from '@src/features/orders/redux/extra-store/actions';
import { OrderStatusEnum } from '@src/features/orders/models/OrderModel';
import { selectorCollapsed } from '@src/features/orders/redux/extra-store/selectors';


const TEXT = {
  new: 'NEW ORDERS',
  error: 'ERROR',
  ready_for_take_off: 'READY FOR TAKE OFF',
  en_route: 'EN ROUTE',
  completed: 'COMPLETED',
  cancelled: 'CANCELLED',
  assigned: 'ASSIGNED',
};

const COLOR = {
  new: '#D9D9D9',
  error: '#CF6679',
  ready_for_take_off: '#81CC67',
  en_route: '#9ACCE9',
  completed: '#D9D9D9',
  cancelled: '#D9D9D9',
  assigned: '#D9D9D9',

};

interface OrderCollapseProps {
  count: number;
  type: OrderStatusEnum;
  children: React.ReactNode;
}

const OrderCollapse: React.FC<OrderCollapseProps> = (props) => {
  const { type, children, count } = props;

  const dispatch = useDispatch();
  const collapsed = useSelector(selectorCollapsed);
  const isDisabled = type !== OrderStatusEnum.NEW && count === 0;

  const onChangeType = (): void => {

    if (isDisabled) return;
    
    if (collapsed.includes(type)) {
      dispatch(setCollapsed(collapsed.filter((p) => p !== type)));
      return;
    }
    dispatch(setCollapsed([...collapsed, type]));
  };

  return (
    <Flex width='100%' flexDirection='column'>

      <Flex 
        gap='8px' 
        alignItems='center' 
        justifyContent='space-between'
        cursor='pointer'
        onClick={onChangeType}>

        <Flex gap='4px' alignItems='center'>
          <StatusPoint color={COLOR[type]} />
          <Text 
            whiteSpace='nowrap' 
            color={isDisabled ? 'text.secondary' : 'text.snow'}> 
            {TEXT[type]} ({count}) 
          </Text>
        </Flex>

        <IconButton  
          width='24px'
          height='24px'
          variant='text'
          aria-label='order-down-icon' 
          transform={`rotate(${collapsed.includes(type) ? 180 : 0}deg)`}
          icon={<ChevronDownIcon color='text.snow' />} 
        />
      </Flex>

      <Collapse in={collapsed.includes(type)}>
        {children}
      </Collapse>

    </Flex>
  );
};

export default OrderCollapse;