import React from 'react';
import { Flex, Text } from '@chakra-ui/react';

const Header: React.FC = () => {
  return (
    <Flex gap='5px' direction='column'>
      <Text 
        color='text.secondary' 
        fontSize='18px' 
        fontWeight={500}>
        Welcome!
      </Text>
      <Text 
        color='white' 
        fontSize='24px' 
        fontWeight={500}> 
        Pre-flight Checklist 
      </Text>
    </Flex>
  );
};

export default Header;
