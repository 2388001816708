import React from 'react';

import { Tabs, TabList, Tab, TabPanel, TabPanels } from '@chakra-ui/react';
import { withDefaultLayout } from '@src/layouts';
import { ParameterPanel, UserPanel } from '../panels';

const tabs = [
  { id: 1, name: 'PARAMETER' },
  { id: 2, name: 'USER' },
];

const SettingsPage: React.FC = () => {

  return (
    
    <Tabs w='100%' maxW='900px' m='24px auto' variant='enclosed' isFitted>

      <TabList borderColor='border.secondary' mb='24px'>
        {tabs.map((tab) => (
          <Tab 
            key={tab.id} 
            p='12px'
            fontSize='14px'
            color='text.secondary' 
            _selected={{ 
              fontWeight: 600,
              color: 'text.primary',
              borderColor: 'border.primary', 
            }}>
            {tab.name}
          </Tab>
        ))}
      </TabList>

      <TabPanels>
        <TabPanel> <ParameterPanel />  </TabPanel>
        <TabPanel> <UserPanel /> </TabPanel>
      </TabPanels>

    </Tabs>
    
  ); 
};



export default withDefaultLayout(SettingsPage);