import React from 'react';
import { Button, Flex, Text } from '@chakra-ui/react';
import { DroneIcon } from '@src/components';
import { AddDroneProps } from './types';
import { PRIMARY_BRAND } from '@src/styles/chakra/colors';

const AddDrone: React.FC<AddDroneProps> = (props) => {
  const { drone, onSelectDrone,  ...flexProps } = props;

  return (
    <Flex 
      gap='12px'
      p='18px 32px'
      direction='column'
      borderRadius='8px'
      bg='linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), #121212'
      boxShadow='0px 1px 3px 0px rgba(0, 0, 0, 0.20), 0px 2px 1px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14)'
      {...flexProps}>

      <Flex gap='8px' align='center'>
        <DroneIcon iconColor={PRIMARY_BRAND} />
        <Text 
          color='white'
          fontSize='14px'
          fontWeight={600}> 
          {drone.serialNumber} 
        </Text>
      </Flex>

      <Button 
        size='sm' 
        variant='outline' 
        colorScheme='primaryBrand'
        onClick={() => onSelectDrone(drone)}> 
        Register 
      </Button>

    </Flex>
  );
};

export default AddDrone;