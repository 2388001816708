import { axiosBaseQuery } from '@src/service';
import { createApi } from '@reduxjs/toolkit/dist/query/react';

// types
import { ISignInForm } from '../pages/sign-in-page/types';
import { ISignUpForm } from '../pages/sign-up-page/types';
import { ISignInResponse } from './types';

export const authApi = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: '' }),
  reducerPath: 'authApi',
  endpoints: (builder) => ({
    signIn: builder.mutation<ISignInResponse, ISignInForm>({
      query: (data) => ({
        url: '/authenticate',
        method: 'POST',
        data,
      })
    }),
    signUp: builder.mutation<void, ISignUpForm>({
      query: (data) => ({
        url: '/register',
        method: 'POST',
        data,
      })
    })
  }),
    
});

export const { useSignInMutation, useSignUpMutation } = authApi;