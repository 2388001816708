import React from 'react';
import { Button, Flex, useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectorSelectedGroupOrder } from '@src/features/orders/redux/extra-store/selectors';
import { useCancelOrderMutation } from '@src/features/orders/redux/api';

const GroupOrderPopupActions: React.FC = () => {

  const toast = useToast();
  const navigate = useNavigate();
  const selected = useSelector(selectorSelectedGroupOrder);
  const [cancelOrder, { isLoading }] = useCancelOrderMutation();

  const redirectToModify = (): void => navigate(`/app/orders/modifyRoute/${selected?.missionId}`);

  const onCancel = async (): Promise<void> => {
    if (selected && selected.missionId) {
      cancelOrder({ id: selected?.missionId }).unwrap()
        .then(() => {
          toast({ 
            status: 'success', 
            isClosable: true, 
            title: 'Your request was submitted',  
          });
        })
        .catch(() => {
          toast({ 
            status: 'error', 
            isClosable: true, 
            title: 'Something went wrong',  
          });  
        });
    }
  };


  return (
    <Flex gap='12px'>
      <Button 
        variant='brand' 
        fontWeight={600}
        onClick={redirectToModify}
        isLoading={isLoading}> 
        Modify 
      </Button>
      <Button 
        color='text.snow'
        variant='outline' 
        colorScheme='gray' 
        fontWeight={600}
        onClick={onCancel}
        isLoading={isLoading}
        sx={{ _hover: { bg: 'transparent' } }}> 
        Cancel 
      </Button>
    </Flex>
  );
};

export default GroupOrderPopupActions;