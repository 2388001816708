import { DroneModel } from "@src/features/drones/models";
import { OrderModel } from "../models/OrderModel";

export const convertRoute = (drone: DroneModel | null, orders: OrderModel[]) => {
  if (drone) {
    return [
      getLatLngFromDrone(drone),
      ...orders.map((order) => getLatLngFromOrder(order)),
      getLatLngFromDrone(drone),
    ]; 
  }
  return orders.map((order) => getLatLngFromOrder(order));
};


const getLatLngFromDrone = (drone: DroneModel | null) => {
  return {
    lat: Number(drone?.latitude),
    lng: Number(drone?.longitude),
  };
};
  
const getLatLngFromOrder = (order: OrderModel | null) => {
  return {
    lat: Number(order?.deliveryLocation?.latitude),
    lng: Number(order?.deliveryLocation?.longitude),
  };
};