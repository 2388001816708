import React from 'react';
import { DroneMarker, GoogleMap, OrderMarker, RouteLine } from '@src/components';
import { Flex } from '@chakra-ui/react';
import { AssignMapProps } from './types';
import { convertRoute } from '../../shared';

const Map: React.FC<AssignMapProps> = (props) => {

  const { newOrders, missionOrders, selectedDrone, drones } = props;

  const path = React.useMemo(() => convertRoute(selectedDrone, missionOrders), 
    [selectedDrone, missionOrders]
  );

  return (
    <Flex w='100%' minH='100%' pb='80px'>
      <GoogleMap>

        <RouteLine status='future' path={path} />
        <RouteLine status='return' path={selectedDrone? path.slice(-2) : []}/>

        {newOrders.map((order) => (
          <OrderMarker 
            key={order.id}
            status='default'
            position={{ 
              lat: Number(order?.deliveryLocation.latitude), 
              lng: Number(order?.deliveryLocation.longitude) 
            }} />
        ))}

        {missionOrders.map((order) => (
          <OrderMarker 
            key={order.id}
            status='active'
            position={{ 
              lat: Number(order?.deliveryLocation.latitude), 
              lng: Number(order?.deliveryLocation.longitude) 
            }} />
        ))}

        {drones.map((drone) => {
          const selected = selectedDrone?.id === drone?.id;
          return (
            <DroneMarker 
              key={drone.id} 
              status={selected ? 'active' : 'default'}
              position={{
                lat: Number(drone?.latitude), 
                lng: Number(drone?.longitude) 
              }} />
          );
        })}
        
      </GoogleMap>
    </Flex>
  );
};

export default Map;