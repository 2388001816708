import { RootState } from '@src/store';
import { createSelector } from '@reduxjs/toolkit';

const GoogleMapReducer = (state: RootState): RootState['GoogleMapReducer'] => state.GoogleMapReducer;
const selectorGoogleMap = createSelector([GoogleMapReducer], (state) => state);

export const selectGoogleMapType = createSelector([selectorGoogleMap], (state) => state.type);
export const selectGoogleMapZoom = createSelector([selectorGoogleMap], (state) => state.zoom);
export const selectGoogleMapCenter = createSelector([selectorGoogleMap], (state) => state.center);
export const selectGoogleMapLatitude = createSelector([selectorGoogleMap], (state) => state.latitude);
export const selectGoogleMapLongitude = createSelector([selectorGoogleMap], (state) => state.longitude);

