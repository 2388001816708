export const DEFAULT_GOOGLE_MAP_LATITUDE = 43.238949;
export const DEFAULT_GOOGLE_MAP_LONGITUDE = 76.889709;
export const MAP_CENTER_STORAGE_KEY = 'asend.google.maps.center.key';

export const getInitialCenter = (): google.maps.LatLngLiteral => {
  const target = JSON.parse(localStorage.getItem(MAP_CENTER_STORAGE_KEY) || '{}');

  if (target.lat && target.lng) {
    return target;
  }
  return  { lat: DEFAULT_GOOGLE_MAP_LATITUDE, lng: DEFAULT_GOOGLE_MAP_LONGITUDE };
};