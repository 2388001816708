import React from 'react';
import { Flex } from '@chakra-ui/react';
import { DroneItem } from '../../components';
import { EmptyContent } from '@src/components';
import { useSelectDrone } from '@src/features/orders/hooks';
import { SectionLoading } from '@src/features/orders/components';
import { DronesSectionProps } from './types';


const DronesSection: React.FC<DronesSectionProps> = (props) => {
  const { drones, isLoading } = props;
  const { selectedDrone, onSelectDrone } = useSelectDrone();

  if (isLoading) {
    return <SectionLoading />;
  }

  if (drones.length < 1) {
    return <EmptyContent> No drones </EmptyContent>;
  }
    
  return (
    <Flex gap='8px' direction='column'>
      {drones?.map((drone) => (
        <DroneItem
          key={drone.id} 
          drone={drone} 
          selected={selectedDrone}
          onSelectDrone={onSelectDrone}
        />)
      )}
    </Flex>
  );
};

export default DronesSection;