import { axios } from './get-axios';
import Axios, { AxiosRequestConfig } from "axios";

// types
import { type IAxiosBaseUrl } from './types';
import { type BaseQueryFn } from '@reduxjs/toolkit/query/react';

export const instance = Axios.create({
  baseURL: 'https://api.asend.ai/api/v1',
});


instance.interceptors.request.use((request: any) => {
  const token = localStorage.getItem('token');
  if (token) {
    request.headers["Authorization"] = `Bearer ${token}`;
  }
  request.headers["Content-Type"] = "application/json";
  return request;
});


export const axiosBaseQuery = ({ baseUrl }: IAxiosBaseUrl): BaseQueryFn<{
  url: string
  method: AxiosRequestConfig['method']
  data?: AxiosRequestConfig['data']
  params?: AxiosRequestConfig['params']
}> => async ({ url, method, data, params }) => {
  try {
    const axiosData = await axios({ url: baseUrl + url, method, data, params });
    return { data: axiosData.data };
  } catch (axiosError) {
    return { error: axiosError };
  }
};





