import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ZonesPage from './pages/zones-page';

const ZoneFeature: React.FC = () => {
  return (
    <Routes>
      <Route path='/' element={<ZonesPage />} />
    </Routes>
  );
};

export default ZoneFeature;