import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { AttitudeIndicator, HeadingIndicator } from 'react-typescript-flight-indicators';
import { WebSocketContext } from '@src/context/ws-context';
import { ChanelEnum } from '@src/context/ws-context/types';
import { TelemetryModel } from '@src/features/orders/models/TelemetryModel';

const FlightIndicator: React.FC = () => {
  const [telemetry, setTelemetry] = React.useState<TelemetryModel>();
  const { subscribe, unsubscribe } = React.useContext(WebSocketContext);

  React.useEffect(() => {
    subscribe(ChanelEnum.TELEMETRY, (event: { value: TelemetryModel }) => {
      setTelemetry(event.value || {});
    });
    return () => unsubscribe(ChanelEnum.TELEMETRY);
  }, [subscribe, unsubscribe]);


  return (
    <Flex position='absolute' bottom={0} left={10} zIndex={1000}>
      <Box sx={{ clipPath: 'circle(41% at 50% 48.5%)' }}>
        <AttitudeIndicator size='180px' pitch={telemetry?.pitch_deg} roll={telemetry?.roll_deg} />
      </Box>
      <Box sx={{ ml: '-20px', clipPath: 'circle(41% at 50% 48.5%)' }}>
        <HeadingIndicator size='180px' heading={telemetry?.yaw_deg}/>
      </Box>
    </Flex>
  );
};

export default FlightIndicator;