import { OrderGroupModel, OrderStatusEnum } from "../../models/OrderModel";
import { IOrders } from "../types";

export const getGroupOrderWithStatus = (selected: OrderGroupModel, groupOrders: IOrders): { status: OrderStatusEnum, order: OrderGroupModel } => {
  const { new: NewOrders, ...otherGroupOrders } = groupOrders;
  for (const [key, orders] of Object.entries({...otherGroupOrders})) {
    const selectedOrder = orders.find((o) => o.missionId === selected?.missionId);
    if (selectedOrder) {
      return { status: key as OrderStatusEnum, order: selectedOrder };
    }
  }
  return { status: selected.status, order: selected };
};