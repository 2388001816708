import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { useDragDrop } from '@src/features/orders/hooks';
import { CardWrapper } from '@src/features/orders/components';

import { OrderItemProps } from './types';

const OrderItem: React.FC<OrderItemProps> = (props) => {
  const { itemIdx, order, onDragDropOrder, selected, onSelectOrder } = props;
  const { ref, handlerId, opacity } = useDragDrop({ itemIdx, order, onDragDropOrder });
  return (
    <CardWrapper 
      ref={ref}
      data-handler-id={handlerId}
      sx={{ opacity }}
      isSelected={selected?.id === order.id}
      onClick={() => onSelectOrder(order)}>

      <Flex gap='4px' alignItems='center' justifyContent='space-between'>
        <Flex direction='column'>
          <Text color='text.snow' fontSize='14px' noOfLines={2}> #{order.id} {order.clientName}  </Text>
          <Text color='text.secondary' fontSize='12px' noOfLines={4}> {order.deliveryLocation.address}  </Text>
        </Flex>
      </Flex>
    </CardWrapper>
  );
};

export default OrderItem;