import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(inputAnatomy.keys);

const baseStyle = definePartsStyle({
  field: {
    width: '100%',
    color: '#FFFFFF',
    fontSize: '14px',
    fontWeight: 500,
    transition: 'transform 0.3s ease-out, border-color 0.15s ease-out',
  },
    
    
});

export const inputTheme = defineMultiStyleConfig({ 
  baseStyle,
});