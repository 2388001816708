import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Flex, useToast } from '@chakra-ui/react';
import { OrderModel } from '@src/features/orders/models/OrderModel';
import { useCancelOrderMutation } from '@src/features/orders/redux/api';
import { useDispatch } from 'react-redux';
import { setSelectedNewOrder } from '@src/features/orders/redux/extra-store/actions';

interface OrderPopupActionsProps {
  order: OrderModel;
}

const OrderPopupActions: React.FC<OrderPopupActionsProps> = (props) => {

  const { order } = props;

  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [cancelOrder, { isLoading }] = useCancelOrderMutation();

  const onCancel = async (): Promise<void> => {
    cancelOrder({ id: order.id }).unwrap()
      .then(() => {
        toast({ 
          status: 'success', 
          isClosable: true, 
          title: 'Your request was submitted',  
        });
        dispatch(setSelectedNewOrder(null));
      })
      .catch((e) => {
        toast({ 
          status: 'error', 
          isClosable: true, 
          title: e?.message || 'Something went wrong',  
        });  
      });
  };

  const redirectToUpdateOrder = (): void => navigate(`/app/orders/update/${order.id}`);

  return (
    <Flex gap='12px'>
      <Button 
        variant='brand' 
        fontWeight={600} 
        isLoading={isLoading}
        onClick={redirectToUpdateOrder}> 
        Update 
      </Button>
      <Button 
        color='text.snow'
        variant='outline'
        colorScheme='gray' 
        fontWeight={600} 
        onClick={onCancel}
        isLoading={isLoading}
        sx={{ _hover: { bg: 'transparent' } }}> 
        Cancel 
      </Button>
    </Flex>
  );
};

export default OrderPopupActions;