import { useDispatch, useSelector } from "react-redux";
import { DroneModel } from "@src/features/drones/models";
import { setSelectedDrone } from "@src/features/drones/redux/extra-store/actions";
import { selectSelectedDrone } from "@src/features/drones/redux/extra-store/selectors";
import { setGoogleMapCenter, setGoogleMapLatitude, setGoogleMapLongitude } from "@src/components/google-map/redux/actions";

const useSelectDrone = () => {
  const dispatch = useDispatch();
  const selected = useSelector(selectSelectedDrone);

  const onSelect = (drone: DroneModel) => {
    if (selected?.id === drone.id) {
      dispatch(setSelectedDrone(null));
      return;
    }

    dispatch(setSelectedDrone(drone));
    const { latitude, longitude } = drone;
    dispatch(setGoogleMapLatitude(Number(latitude)));
    dispatch(setGoogleMapLongitude(Number(longitude)));
    dispatch(setGoogleMapCenter({ lat: Number(latitude), lng: Number(longitude) }));
  };

  return {
    selectedDrone: selected,
    onSelectDrone: onSelect,
  };
};

export default useSelectDrone;