import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PopupOrdersList, PopupWrapper } from '../components';
import { selectorSelectedGroupOrder } from '@src/features/orders/redux/extra-store/selectors';
import { OrderModel } from '@src/features/orders/models/OrderModel';
import { setGoogleMapCenter } from '@src/components/google-map/redux/actions';


const GroupOrderCompletedPopup: React.FC = () => {

  const dispatch = useDispatch();
  const selected = useSelector(selectorSelectedGroupOrder);

  const onSelectGroupOrder = (order: OrderModel): void => {
    dispatch(setGoogleMapCenter({
      lat: Number(order.deliveryLocation.latitude),
      lng: Number(order.deliveryLocation.longitude),
    }));
  };

  return (
    <PopupWrapper title={`Drone - (${selected?.drone.serialNumber})`}>
      <PopupOrdersList onClick={onSelectGroupOrder} />
    </PopupWrapper>
  );
};

export default GroupOrderCompletedPopup;