import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { IFetchGeocodeRequest, IMapInitialState } from './types';
import { MAP_CENTER_STORAGE_KEY } from './utils';

const fetchGeocode: CaseReducer<IMapInitialState, PayloadAction<IFetchGeocodeRequest>> = () => {};

const setGoogleMapZoom: CaseReducer<IMapInitialState, PayloadAction<IMapInitialState['zoom']>> = (state, action) => {
  state.zoom = action.payload;
};

const setGoogleMapCenter: CaseReducer<IMapInitialState, PayloadAction<IMapInitialState['center']>> = (state, action) => {
  if (action.payload.lat && action.payload.lng) {
    localStorage.setItem(MAP_CENTER_STORAGE_KEY, JSON.stringify(action.payload));
    state.center = action.payload;
  }
};

const setGoogleMapLatitude: CaseReducer<IMapInitialState, PayloadAction<IMapInitialState['latitude']>> = (state, action) => {
  state.latitude = action.payload;
};

const setGoogleMapLongitude: CaseReducer<IMapInitialState, PayloadAction<IMapInitialState['longitude']>> = (state, action) => {
  state.longitude = action.payload;
};

const setGoogleMapType: CaseReducer<IMapInitialState, PayloadAction<IMapInitialState['type']>> = (state, action) => {
  state.type = action.payload;
};

export default { 
  fetchGeocode,

  setGoogleMapType, 
  setGoogleMapZoom, 
  setGoogleMapCenter, 
  setGoogleMapLatitude, 
  setGoogleMapLongitude 
};