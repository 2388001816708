import React from 'react';
import AutoSelect from 'react-select';
import { FormControl } from '@src/components';
import { CloseButton } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { selectorFormattedAdress } from '@src/features/orders/redux/extra-store/selectors';
import { setFormattedAddress } from '@src/features/orders/redux/extra-store/actions';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import { setGoogleMapCenter, setGoogleMapLatitude, setGoogleMapLongitude } from '@src/components/google-map/redux/actions';
import { GoogleSearchInputProps } from './types';

const GoogleSearchInput: React.FC<GoogleSearchInputProps> = (props) => {

  const { error, errorText, setValueAddress } = props;

  const { suggestions, setValue, value: inputValue } = usePlacesAutocomplete();

  const dispatch = useDispatch();
  const formattedAddress = useSelector(selectorFormattedAdress);

  const onChangeAddress = async (address?: string) => {
    const [firstCoordinate] = await getGeocode({ address });
    const { lat, lng } = await getLatLng(firstCoordinate);

    dispatch(setGoogleMapLatitude(lat));
    dispatch(setGoogleMapLongitude(lng));
    dispatch(setGoogleMapCenter({ lat, lng }));

    dispatch(setFormattedAddress(address || ''));
  };

  const reset = () => {
    setValue('');
    dispatch(setFormattedAddress(''));
  };

  const options = React.useMemo(() => {
    return suggestions.data.map((s) => ({
      label: s.description,
      value: s.description,
    }));
  }, [suggestions]);

  const value = React.useMemo(() => {
    if (formattedAddress) {
      return {
        label: formattedAddress,
        value: formattedAddress,
      };
    }
    return null;
  }, [formattedAddress]);



  React.useEffect(() => {
    setValueAddress('address', formattedAddress);
  }, [formattedAddress, setValueAddress]);


  return (
    <FormControl isInvalid={error} helperText={errorText}>
      <AutoSelect 
        isClearable
        options={options} 
        value={value}
        inputValue={inputValue}
        placeholder='Delivery Location'
        onChange={(e) => onChangeAddress(e?.value)}
        onInputChange={(val) => setValue(val)}
        components={{
          ClearIndicator: () => <CloseButton color='white' onClick={reset} />
        }}
        styles={{ 
          control(base) {
            base.paddingLeft = '8px';
            base.background = '#121212';
            base.borderColor = error ? '#E53E3E' : 'white';
            base.height = '48px';
            return base;
          },
          placeholder(base) {
            base.color = '#718096';
            base.fontSize='14px';
            base.fontWeight = 500;
            return base;
          },
          input(base) {
            base.color = 'white';
            return base;
          },
          singleValue(base) {
            base.color = 'white';
            return base;
          },
        }} />
    </FormControl>
  );
};

export default GoogleSearchInput;