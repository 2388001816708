import { DroneModel } from "@src/features/drones/models";
import { WayPointModel } from "../models/WayPointModel";
import { OrderModel } from "../models/OrderModel";

export const convertWPtoPath = (wp: WayPointModel) => ({
  lat: wp?.latitude, 
  lng: wp?.longitude, 
});

export const convertDroneToWP = (drone: DroneModel): WayPointModel => {
  return {
    type: 'drone',
    latitude: Number(drone?.latitude),
    longitude: Number(drone?.longitude),
  };
};

export const convertOrderToWP = (order: OrderModel): WayPointModel => {
  return {
    type: 'order',
    orderId: order?.id,
    speed: order?.speed,
    tetherId: order?.tetherId,
    altitude: order?.altitude,
    latitude: Number(order?.deliveryLocation?.latitude),
    longitude: Number(order?.deliveryLocation?.longitude),
    deliveryAltitude: Number(order?.deliveryLocation?.altitude),
  };
};