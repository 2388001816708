import React from 'react';
import { Button, Flex } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { DroneList } from './components';

const DronesSidebar: React.FC = () => {
  const navigate = useNavigate();
  
  const redirectToAddDrone = () => navigate('/app/drones/register');

  return (
    <Flex 
      p='12px'
      w='100%' 
      minW='400px'
      maxW='400px'
      direction='column'
      bgColor='block.cloud'>

      <Button mb='18px' minH='40px' variant='brand' onClick={redirectToAddDrone}> Add drone </Button>

      <DroneList />

    </Flex>
  );
};

export default DronesSidebar;