import React from 'react';
import { DroneMarker } from '@src/components';
import { WebSocketContext } from '@src/context/ws-context';
import { ChanelEnum } from '@src/context/ws-context/types';
import { FLightDroneModel } from '@src/features/orders/models/FlightDroneModel';
import { FlightDroneProps } from './types';


const FlighDrone: React.FC<FlightDroneProps> = (props) => {
  const { defaultLatitude, defaultLongitude } = props;
  
  const { subscribe, unsubscribe } = React.useContext(WebSocketContext);
  const [flightData, setFlightData] = React.useState<FLightDroneModel>();

  React.useEffect(() => {
    subscribe(ChanelEnum.FLIGHT_DATA, (event: { flightData: FLightDroneModel }) => {
      setFlightData(event?.flightData || {});
    });
    return () => unsubscribe(ChanelEnum.FLIGHT_DATA);
  }, [subscribe, unsubscribe]);

  return (
    <DroneMarker
      status='active'
      position={{ 
        lat: Number(flightData?.latitude) || defaultLatitude, 
        lng: Number(flightData?.longitude) || defaultLongitude 
      }} 
    />
  );
};

export default FlighDrone;