import { selectAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(selectAnatomy.keys);

const baseStyle = definePartsStyle({
  field: {
    width: '100%',
    fontSize: '14px',
    fontWeight: 500,
    transition: 'transform 0.3s ease-out, border-color 0.15s ease-out',
  }, 
  icon: {
    color: 'white',
  },
});

export const selectTheme = defineMultiStyleConfig({ 
  baseStyle,
});