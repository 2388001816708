import { Flex, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import React from 'react';

const TakeDroneInfo: React.FC = () => {
  return (
    <Flex 
      gap='8px'
      color='text.snow'
      fontSize='12px'
      fontWeight={600} 
      direction='column'>
      <Text> Preflight Checklist </Text>
      <Text ml='8px'> Make sure: </Text>
      <UnorderedList ml='30px'>
        <ListItem>Payload is loaded</ListItem>
        <ListItem>Payload is secured</ListItem>
        <ListItem>Flight is authorized</ListItem>
        <ListItem>Propellers are secure</ListItem>
        <ListItem>Nothing is obstructing the drone’s path within 10 ft radius area</ListItem>
        <ListItem>Stand at least 10 ft away from the drone</ListItem>
      </UnorderedList>
    </Flex>
  );
};

export default TakeDroneInfo;