import React from 'react';
import { Button, Flex } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setCollapsed, setSelectedGroupOrder, setSelectedNewOrder } from '../../redux/extra-store/actions';
import NewOrdersList from './new-orders/new-orders-list';
import GroupOrdersList from './group-orders/group-orders-list';
import { WebSocketContext } from '@src/context/ws-context';
import { ChanelEnum } from '@src/context/ws-context/types';
import { useGetOrdersQuery } from '../../redux/api';
import { selectorCollapsed, selectorSelectedGroupOrder } from '../../redux/extra-store/selectors';
import { getGroupOrderWithStatus } from '../../redux/extra-store/utils';

const Sidebar: React.FC = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const collapsed = useSelector(selectorCollapsed);
  const selected = useSelector(selectorSelectedGroupOrder);

  const { data, refetch } = useGetOrdersQuery({ });
  const { subscribe, unsubscribe } = React.useContext(WebSocketContext);

  const redirectToCreateOrder = (): void => navigate('/app/orders/create');

  React.useEffect(() => {
    if (selected && data?.orders) {
      const result = getGroupOrderWithStatus(selected, data.orders);
      dispatch(setSelectedGroupOrder({ ...result.order, status: result.status }));
      if (!collapsed.includes(result.status)) {
        dispatch(setCollapsed([...collapsed, result.status]));
      }
    }
  }, [data]);

  React.useEffect(() => {
    subscribe(ChanelEnum.UPDATE_ORDERS, () => {
      refetch();
    });
    return () => {
      dispatch(setSelectedNewOrder(null));
      dispatch(setSelectedGroupOrder(null));
      unsubscribe(ChanelEnum.UPDATE_ORDERS);
    };
  }, [subscribe, unsubscribe]);

    
  return (
    <Flex 
      p='12px'
      w='100%' 
      maxW='400px'
      direction='column'
      bgColor='block.cloud'>
    
      <Button 
        minH='40px'
        mb='18px'
        variant='brand' 
        onClick={redirectToCreateOrder}> 
        Create Order 
      </Button>
      
      <Flex 
        gap='12px' 
        flexDirection='column'
        overflow='auto'
        sx={{ '::-webkit-scrollbar': {
          width: '0',
          height: '0',
        },
        '-ms-overflow-style': 'none',
        'scrollbar-width': 'none', 
        }}>
        <NewOrdersList />
        <GroupOrdersList />
      </Flex>

    </Flex>
  );
};

export default Sidebar;