import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { sidebarList } from '../consts';
import { NavLink, useLocation } from 'react-router-dom';



export const Sidebar: React.FC = () => {

  const location = useLocation();

  return (
    <Box
      p='30px 10px'
      gap='20px'
      width='60px'
      display='flex'
      flexDirection='column'
      bgColor='block.cloud' 
      height='calc(100vh - 40px)'
      style={{ cursor: 'pointer' }}
      borderRight='1px solid'
      borderColor='text.100'>
      
      {sidebarList.map((item) => (
        <NavLink key={item.id} to={item.url}>
          <Box 
            gap='5px'
            key={item.id} 
            display='flex' 
            alignItems='center'
            flexDirection='column'>
            {item.icon(item.paths.includes(location.pathname) ? '#9E9EF3' : '#A0A0A0')}

            <Text 
              fontSize='12px'
              textAlign='center'
              
              color={item.paths.includes(location.pathname) ? 'txt.primaryBrand' : 'txt.secondary' } >
              {item.name}
            </Text>
          </Box>
        </NavLink>
      ))}
    </Box>
  );
};