import React from 'react';
import { Flex } from '@chakra-ui/react';
import { withDefaultLayout } from '@src/layouts';

import DronesSidebar from './drones-sidebar';
import DronesContent from './drones-content';

const DronesPage: React.FC = () => {
  
  return (
    <Flex w='100%' align='flex-start'>
      <DronesSidebar />
      <DronesContent />
    </Flex>
  );
};

export default withDefaultLayout(DronesPage);