import React from 'react';
import { Button, Flex } from '@chakra-ui/react';
import DroneOrdersHistory from '../drone-orders-history';
import DroneMaintenance from '../drone-maintenance';

const ContentInformation: React.FC = () => {

  const [layout, setLayout] = React.useState<'history' | 'maintenance'>('history');

  return (

    <Flex
      w='100%' 
      h='100%'
      p='8px 12px'
      borderRadius='8px'
      direction='column'
      bgColor='black'>
        
      <Flex h='50px' align='center' mb='24px'>
        <Button 
          w='200px'
          color='white'
          colorScheme='primaryBrand'
          variant={layout === 'history' ? 'solid' : 'unstyled'}
          onClick={() => setLayout('history')}> 
          Order History 
        </Button> 

        <Button 
          w='200px'
          color='white'
          colorScheme='primaryBrand'
          variant={layout === 'maintenance' ? 'solid' : 'unstyled'}
          onClick={() => setLayout('maintenance')}> 
          Maintenance 
        </Button> 
      </Flex>

      {layout === 'history' && <DroneOrdersHistory />}
      {layout === 'maintenance' && <DroneMaintenance />}
      
    </Flex>
    
  );
};

export default ContentInformation;