import { DroneModel } from "@src/features/drones/models";
import { DeliveryLocationModel } from "./DeliveryLocationModel";
import { WayPointModel } from "./WayPointModel";


export interface OrderModel {
    id: number;
    status: OrderStatusEnum;
    createDate: string;
	clientName: string;
	deliveryMethod: string;
	packageContent: string;
	deliveryLocation: DeliveryLocationModel;
	waypoints?: WayPointModel[];
	drone: DroneModel;
	filename?: string;
	totalFlightTime?: number;
	totalDistance?: string;
	tetherId: number;
	speed: number;
	altitude: number;
}

export interface OrderGroupModel {
	missionId: string;
	orders: OrderModel[];
	drone: DroneModel;
	status: OrderStatusEnum;
	waypoints: WayPointModel[];
}

export enum OrderStatusEnum {
    NEW='new',
	READY_FOR_TAKE_OFF = 'ready_for_take_off',
	EN_ROUTE = 'en_route',
	COMPLETED = 'completed',
	CANCELLED = 'cancelled',
	ERROR = 'error',
	ASSIGNED='assigned'
}