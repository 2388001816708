import React from 'react';

// --- UI
import { GlobalLoading } from '@src/components';
import { Routes, Route, Navigate } from 'react-router-dom';
import { fetchInitialized } from './store/App/App.actions';
import { selectInitilized } from './store/App/App.selectors';

import { LoadingStatus } from './shared/status';
import { Libraries, useJsApiLoader } from '@react-google-maps/api';
import { useDispatch, useSelector } from 'react-redux';

import AuthFeature from './features/auth';
import ZoneFeature from './features/zones';
import DroneFeature from './features/drones';
import SettingFeature from './features/settings';
import MaintFeature from './features/maint';
import ChecklistFeature from './features/checklist';
import OrderFeature from './features/orders';
import { GOOGLE_MAP_KEY } from './shared/const';


const libraries = ['places'] as Libraries;

const App: React.FC = () => {
  const dispatch = useDispatch();
  const initialized = useSelector(selectInitilized);
  const { isLoaded } = useJsApiLoader({ googleMapsApiKey: GOOGLE_MAP_KEY, libraries });


  React.useEffect(() => {
    dispatch(fetchInitialized());
  }, [dispatch]);


  if (initialized === LoadingStatus.NEVER || !isLoaded) {
    return <GlobalLoading />;
  }


  return (
    <React.Fragment>
      <Routes>				
        <Route path='/auth/*' element={<AuthFeature />} />
        <Route path='/app/zones/*' element={<ZoneFeature />}/>
        <Route path='/app/maint/*' element={<MaintFeature />} />
        <Route path='/app/drones/*' element={<DroneFeature />} />
        <Route path='/app/orders/*' element={<OrderFeature />}/>
        <Route path='/app/settings/*' element={<SettingFeature />}/>
        <Route path='/app/pre-flight-checklist/*' element={<ChecklistFeature />}/>
        
        <Route path='*' element={<Navigate to='/app/orders' />} />
      </Routes>
    </React.Fragment>
  );
};


export default App;
