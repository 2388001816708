import React from 'react';
import { Button, Flex, Image, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useToast } from '@chakra-ui/react';
import { selectDroneOrders, selectSelectedDrone } from '@src/features/drones/redux/extra-store/selectors';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useResetPartMutation } from '@src/features/drones/redux/api';

const DroneMaintenance: React.FC = () => {
  const toast = useToast();
  const [searchParams] = useSearchParams();
  const [resetPart, { isLoading }] = useResetPartMutation();
  const selectedDrone = useSelector(selectSelectedDrone);
  const orders = useSelector(selectDroneOrders(searchParams.get('droneId')));

  const onResetPart = (part: string) => {
    if (selectedDrone)
      resetPart({ id: selectedDrone.id, part }).unwrap()
        .then(() => 
          toast({ 
            status: 'success', 
            isClosable: true, 
            title: 'Your request was submitted', 
          }))
        .catch(() => 
          toast({ 
            status: 'error', 
            isClosable: true, 
            title: 'Something went wrong',  
          })
        );
  };

  const maintenances = React.useMemo(() => {
    const controller = orders?.data?.dronePart || {};
    return Object.keys(controller).map((key) => ({ 
      name: key || '',
      limitHour: controller[key]?.limitHour || 0,
      totalHour: controller[key]?.totalHour || 0,
    }));
  }, [orders]);



  return (
    <Flex p='12px' gap='24px' justify='space-between'>
      <Flex mt='24px' gap='24px' direction='column'>
        <Flex gap='24px'>
          <Flex color='white' gap='4px' direction='column'>
            <Text fontSize='12px' fontWeight={700}> TOTAL TIME OF FLIGHT </Text>
            <Text fontSize='14px'> {((orders?.data?.totalFlightTime || 0) / 3600).toFixed(1)} hours </Text>
          </Flex>

          <Flex color='white' gap='4px' direction='column'>
            <Text fontSize='12px' fontWeight={700}> DISTANCE </Text>
            <Text fontSize='14px'> {((orders?.data?.totalDistance || 0) / 100).toFixed(1)} km </Text>
          </Flex>
        </Flex>
        <Image 
          minW='100px' 
          minH='100px'
          borderRadius='8px'
          alt='Maintenance photo'
          src='https://via.placeholder.com/440x440' />
      </Flex>

      <Flex bg='red' />

      <TableContainer w='100%' p='12px'>
        <Table variant='unstyled'>

          <Thead>
            <Tr color='white'>
              <Th> Name </Th>
              <Th> Total hour </Th>
              <Th> Hour limit </Th>
              <Th />
            </Tr>
          </Thead>

          <Tbody>
            {maintenances.map((m) => (
              <Tr 
                key={m.name} 
                color='white'
                fontSize='14px'>
                <Td> {m.name} </Td>
                <Td> {m.totalHour} </Td>
                <Td> {m.limitHour} </Td>
                <Td> 
                  <Button 
                    size='sm'
                    variant='outline' 
                    isLoading={isLoading}
                    colorScheme='primaryBrand'
                    onClick={() => onResetPart(m.name)}> 
                    RESET 
                  </Button> 
                </Td>
              </Tr>
            ))}
          </Tbody>

        </Table>
      </TableContainer>

    </Flex>
  );
};

export default DroneMaintenance;