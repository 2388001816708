import React from 'react';
import { FormControl as ChakraFormControl, FormHelperText } from '@chakra-ui/react';
import { FormControlProps } from './types';

const FormControl: React.FC<FormControlProps> = (props) => {
  const {
    children,
    isInvalid, 
    helperText,
    formControlProps,
    formHelperTextProps,
  } = props;
  return (
    <ChakraFormControl isInvalid={isInvalid} {...formControlProps}>

      {children}

      {isInvalid && 
        <FormHelperText 
          color='text.error' 
          fontSize='12px' 
          {...formHelperTextProps}> 
          {helperText} 
        </FormHelperText> }

    </ChakraFormControl>
  );
};

export default FormControl;