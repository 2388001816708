import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SubmitHandler } from 'react-hook-form';
import { Flex, useToast } from '@chakra-ui/react';
import { useCreateOrderMutation } from '../../redux/api';
import { withDefaultLayout } from '@src/layouts';
import { IOrderForm, OrderFormAction, OrderForm, OrderMap } from '../../components';
import { selectGoogleMapLatitude, selectGoogleMapLongitude } from '@src/components/google-map/redux/selectors';
import { IPostCreateOrderRequest } from '../../redux/types';


const CreateOrderPage: React.FC = () => {

  const toast = useToast();
  const navigate = useNavigate();
  const [createOrder, { isLoading: isCreateOrderLoading }] = useCreateOrderMutation();

  const lat = useSelector(selectGoogleMapLatitude);
  const lng = useSelector(selectGoogleMapLongitude);

  const onCreateOrder: SubmitHandler<IOrderForm> = (form) => {
    const { address, deliveryAltitude, ...rest } = form;

    const payload: IPostCreateOrderRequest = {
      ...rest,
      deliveryLocation: {
        address,
        latitude: String(lat),
        longitude: String(lng),
        altitude: deliveryAltitude,
      }
    };
    
    createOrder(payload).unwrap()
      .then(() => navigate('/app/orders'))
      .catch((e) => {
        toast({
          status: 'error',
          isClosable: true,
          title: e?.message || 'Something went wrong',
        });
      });
  };

  return (
    <Flex w='100%'>
      <Flex 
        p='12px'
        w='100%' 
        maxW='400px'
        direction='column'
        bgColor='block.cloud'>
        <OrderForm 
          title='CREATE ORDER' 
          onSubmit={onCreateOrder}>
          <OrderFormAction
            submitText='CREATE ORDER'
            isLoading={isCreateOrderLoading}  />
        </OrderForm>
      </Flex>
      
      <OrderMap />
    </Flex>
  );
};

export default withDefaultLayout(CreateOrderPage);