import React from 'react';
import { Button, Flex, Text } from '@chakra-ui/react';
import { TabActionButtonsProps } from './types';

const TabActionButtons: React.FC<TabActionButtonsProps> = (props) => {
  const { isLoading, goForth, goBack, goForthText, goBackText } = props;

  return (
    <Flex 
      p='12px' 
      width='100%'
      height='80px' 
      gap='12px'
      align='center'
      justifyContent='start'
      position='fixed'
      left={0}
      bottom={0}
      bg='linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), #121212'>

      <Button 
        w='200px' 
        type='submit' 
        fontSize='12px' 
        colorScheme='primaryBrand'
        fontWeight={700}
        isLoading={isLoading}
        onClick={goForth}>
        {goForthText}
      </Button>

      <Button 
        w='200px' 
        fontSize='12px' 
        variant='text' 
        fontWeight={700} 
        onClick={goBack}
        isLoading={isLoading}>
        <Text color='txt.primaryBrand'>
          {goBackText}
        </Text>
      </Button>

    </Flex>
  );
};

export default TabActionButtons;