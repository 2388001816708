import React from 'react';
import { Collapse } from '../components';
import { OrderStatusEnum } from '@src/features/orders/models/OrderModel';
import GroupOrderItem from './group-order-item';
import { Flex } from '@chakra-ui/react';
import { useGetOrdersQuery } from '@src/features/orders/redux/api';


const GroupOrdersList: React.FC = () => {
  const { data } = useGetOrdersQuery({ });
  
  return (
    <React.Fragment>
      {data?.groupOrders.map(([key, groupOrder]) => (
        <Collapse 
          key={key}
          count={groupOrder.length}
          type={key as OrderStatusEnum}>
          
          <Flex py='18px' gap='10px' flexWrap='wrap'>
            {groupOrder.map((order) => (
              <GroupOrderItem
                key={order.missionId} 
                order={order}
                status={key as OrderStatusEnum} />
            ))}
          </Flex>

        </Collapse>
      ))}
    </React.Fragment>
  );
};

export default GroupOrdersList;