import { IMapInitialState } from './types';
import { getInitialCenter } from './utils';

export const DEFAULT_GOOGLE_MAP_ZOOM = 22;
export const DEFAULT_GOOGLE_MAP_LATITUDE = 43.238949;
export const DEFAULT_GOOGLE_MAP_LONGITUDE = 76.889709;
export const DEFAULT_GOOGLE_MAP_TYPE = 'satellite';

export const MAP_ZOOM_STORAGE_KEY = 'asend.google.maps.zoom.key';


const initialState: IMapInitialState = {
  zoom: DEFAULT_GOOGLE_MAP_ZOOM,
  latitude: DEFAULT_GOOGLE_MAP_LATITUDE,
  longitude: DEFAULT_GOOGLE_MAP_LONGITUDE,
  center: getInitialCenter(),
  type: DEFAULT_GOOGLE_MAP_TYPE
};

export default initialState;