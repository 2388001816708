import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { AuthWrapperProps } from './types';


const AuthWrapper: React.FC<AuthWrapperProps> = (props) => {
  const { children } = props;
  return (
    <Flex 
      width='100%'
      minHeight='100vh'
      alignItems='center'
      justifyContent='center'
      bgColor='block.primary'>
      <Box 
        width='100%' 
        maxWidth='520px'
        padding='48px'
        borderRadius='8px'
        sx={{ background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12)), #121212' }}>
        {children}
      </Box>
    </Flex>
  );
};

export default AuthWrapper;