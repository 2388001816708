import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { WebSocketContext } from '@src/context/ws-context';
import { FLightDroneModel } from '@src/features/orders/models/FlightDroneModel';

// icons
import { ReactComponent as DistanceIcon } from '@src/features/orders/assets/distance.svg';
import { ReactComponent as TimeIcon } from '@src/features/orders/assets/time.svg';
import { ReactComponent as BaterryIcon } from '@src/features/orders/assets/baterry.svg';
import { ReactComponent as LocationIcon } from '@src/features/orders/assets/location.svg';
import { ReactComponent as Altitude } from '@src/features/orders/assets/altitude.svg';
import { ReactComponent as LocationAltitude } from '@src/features/orders/assets/locationAltitude.svg';
import { ChanelEnum } from '@src/context/ws-context/types';
import { secondsToMinutes } from '@src/features/orders/shared';


const FlightDroneInfo: React.FC = () => {

  const { subscribe, unsubscribe } = React.useContext(WebSocketContext);
  const [ flightData, setFlightData ] = React.useState<FLightDroneModel>();

  React.useEffect(() => {
    subscribe(ChanelEnum.FLIGHT_DATA_INFO, (event: { flightData: FLightDroneModel }) => {
      setFlightData(event?.flightData || {});
    });
    return () => unsubscribe(ChanelEnum.FLIGHT_DATA);
  }, [subscribe, unsubscribe]);

  return (
    <Flex 
      gap='10px' 
      wrap='wrap'
      color='text.snow'
      fontSize='12px'
      fontWeight={600}>

      <Flex 
        w='100%' 
        maxW='183px' 
        align='center' 
        justify='center' 
        gap='8px'>
        <DistanceIcon width='20px' />
        <Text> 
          {flightData?.distance?.toFixed() || '___'} m 
        </Text>
      </Flex>

      <Flex 
        w='100%' 
        maxW='183px' 
        align='center' 
        justify='center' 
        gap='8px'>
        <TimeIcon width='20px' />
        <Text> 
          {flightData?.eta ? secondsToMinutes(flightData.eta) : '___'} m 
        </Text>
      </Flex>

      <Flex 
        w='100%'
        maxW='183px' 
        align='center'
        justify='center'
        gap='8px'>
        <BaterryIcon width='20px' />
        <Text> 
          {flightData?.batteryHealth || '___'} % 
        </Text>
      </Flex>

      <Flex 
        w='100%' 
        maxW='183px' 
        align='center' 
        justify='center'
        gap='8px'>
        <LocationIcon width='20px' />
        <Text>
            x: {flightData?.latitude?.toFixed(2) || '___'} y: {flightData?.longitude?.toFixed(2) || '___'}
        </Text>
      </Flex>

      <Flex 
        w='100%' 
        maxW='183px' 
        align='center'
        justify='center'
        gap='8px'>
        <Altitude width='20px' />
        <Text>
          {flightData?.speed?.toFixed(2) || '___'} m/s
        </Text>
      </Flex>
 
      <Flex 
        w='100%' 
        maxW='183px' 
        align='center' 
        justify='center'
        gap='8px'>
        <LocationAltitude width='20px' />
        <Text>
          {flightData?.altitude?.toFixed(2) || '___'} m
        </Text>
      </Flex>

    </Flex>
  );
};

export default FlightDroneInfo;