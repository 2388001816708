import React from 'react';
import { Marker } from '@react-google-maps/api';
import { OrderMarkerProps } from './types';

import defaultOrder from './assets/order-default.svg';
import activeOrder from './assets/order-active.svg';


const OrderMarker: React.FC<OrderMarkerProps> = (props) => {
  const { status = 'default', ...rest } = props;

  if (status === 'active') {
    return (
      <Marker
        icon={{
          url: activeOrder,
          scaledSize: new window.google.maps.Size(50, 50),
          origin: new window.google.maps.Point(0, 0),
          anchor: new window.google.maps.Point(25, 40),
        }}
        {...rest} 
    
      />); 
  }


  return (
    <Marker
      icon={{
        url: defaultOrder,
        scaledSize: new window.google.maps.Size(50, 50),
        origin: new window.google.maps.Point(0, 0),
        anchor: new window.google.maps.Point(25, 40),
      }}
      {...rest} 
    />
  );
};

export default OrderMarker;