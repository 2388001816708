import React from 'react';
import { RouteLineProps } from './types';
import { Polyline } from '@react-google-maps/api';
import { line } from './constants';

const RouteLine = React.forwardRef<any, RouteLineProps>((props, ref) => {
  const { status = 'future', options,  ...rest } = props;
  return <Polyline ref={ref} options={{ ...options, ...line[status]}} {...rest} />;
});

RouteLine.displayName = 'RouteLine';

export default RouteLine;