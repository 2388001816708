import { combineReducers } from "redux";
import { GeneralReducer } from '@src/store/General';


import { AppReducer } from '@src/store/App';
import { GoogleMapReducer } from '@src/components/google-map/redux';
import { AuthReducer } from '@src/features/auth/redux';
import { OrderReducer } from '@src/features/orders/redux';
import { DroneReducer as NewDroneReducer } from '@src/features/drones/redux';

import { authApi } from "@src/features/auth/redux/api";
import { settingApi } from "@src/features/settings/redux/api";
import { droneApi } from "@src/features/drones/redux/api";
import { orderApi } from "@src/features/orders/redux/api";


export const rootReducer = combineReducers({
  AppReducer,
  GeneralReducer,
  NewDroneReducer,
  AuthReducer,
  GoogleMapReducer,
  OrderReducer,
  [authApi.reducerPath]: authApi.reducer,
  [droneApi.reducerPath]: droneApi.reducer,
  [orderApi.reducerPath]: orderApi.reducer,
  [settingApi.reducerPath]: settingApi.reducer,
});