import React from 'react';


interface CheckListIconProps {
    color?: string;
}
export const CheckListIcon: React.FC<CheckListIconProps> = (props) => {
  const { color = '#A0A0A0' } = props;
  return (
    <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="icon-park-outline:checklist">
        <path id="Vector" d="M15.5859 5.46094L19.2526 9.1276M19.2526 5.46094L15.5859 9.1276M20.1693 14.6276L16.9609 18.2943L15.1276 16.4609M11.9193 5.46094H1.83594V9.1276H11.9193V5.46094ZM11.9193 14.6276H1.83594V18.2943H11.9193V14.6276Z" stroke={color} strokeWidth="1.83333" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
    </svg>
  );
};

