import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { SectionWrapper } from '../../components';
import { ModifySidebarProps } from './types';
import { DronesSection, OrdersSection } from './sections';

const Sidebar: React.FC<ModifySidebarProps> = (props) => {
  const { 
    selectedDrone,
    
    missionOrders, 
    isMissionOrdersLoading, 
    
    onDragDropMissionOrder 
  } = props;

  return (
    <Flex 
      p='12px' 
      pb='100px' 
      gap='18px' 
      width='100%' 
      maxWidth='400px' 
      direction='column'
      bgColor='block.cloud'
      overflowY='auto'>

      <Text color='white' fontSize='22px'> Plan Route </Text>

      <SectionWrapper title='Mission Orders'>
        <OrdersSection 
          orders={missionOrders} 
          isLoading={isMissionOrdersLoading}
          onDragDropOrder={onDragDropMissionOrder} />
      </SectionWrapper>

      {selectedDrone && 
        <SectionWrapper title='Drone'>
          <DronesSection
            drone={selectedDrone}
            isLoading={isMissionOrdersLoading} />
        </SectionWrapper>
      }

    </Flex>
  );
};

export default Sidebar;