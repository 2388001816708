import React from 'react';
import chakraTheme from '@src/styles/chakra';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Provider } from 'react-redux';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from '@src/store';
import '@src/styles/global.css';
import { WSProvider } from './context/ws-context';
import { ChakraProvider } from '@chakra-ui/react';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter>	
    <ChakraProvider theme={chakraTheme}>
      <DndProvider backend={HTML5Backend}>
        <Provider store={store}>
          <WSProvider 
            getWS={(): void => {}} 
            subscribe={(): void => {}} 
            unsubscribe={(): void => {}}>
              
            <App />

          </WSProvider>
        </Provider>
      </DndProvider>
    </ChakraProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
