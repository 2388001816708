const app = '/app';

export const routes = {
  app: app,

  // auth
  signIn: 'auth/login',
  signUp: 'auth/registration',
  authActivate: 'auth/activate',

  zones: '/app/zones',
  maint: '/app/maint',
  settings: '/app/settings',
  preFlightCheckList: '/app/pre-flight-checklist',
	
	
  // order
  order: `${app}/orders`,
  newOrder: `${app}/new-orders-page`,
  createOrder: `${app}/orders/create`,
  updateOrder: `${app}/orders/update/:orderId`,
  assignDrone: `${app}/orders/assignDrone`,
  modifyRoute: `${app}/orders/modifyRoute/:missionId`,
	
  // drone
  drone: `${app}/drones`,
  newDrones: `${app}/new-drones`,
  registerDrone: `${app}/drones/register`,

  // setting
  users: `${app}/users`,
  parameters: `${app}/parameters`,
};