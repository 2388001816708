import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { SectionWrapperProps } from './types';

const SectionWrapper: React.FC<SectionWrapperProps> = (props) => {
  const { title, children } = props;
  return (
    <Flex gap='8px' direction='column'>
      <Text color='white' fontSize='16px' fontWeight={700}> {title} </Text>
      {children}
    </Flex>
  );
};

export default SectionWrapper;