import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { BatteryHealth } from '@src/components';
import { CardWrapper } from '@src/features/orders/components';

import { DroneItemProps } from './types';


const DroneItem: React.FC<DroneItemProps> = (props) => {
  const { drone } = props;

  return (
    <CardWrapper isSelected={false}>

      <Flex gap='8px' align='center' fontSize='14px'>
        <Text color='white' fontWeight={700}> {drone?.name} </Text>
        <Text color='txt.secondary'> {drone?.serialNumber} </Text>
      </Flex>

      <Flex justifyContent='space-between'>
        <Text color='white' fontWeight={700}> {drone?.status} </Text>
        <BatteryHealth percent={drone?.batteryHealth} />
      </Flex>

    </CardWrapper>
  );
};

export default DroneItem;