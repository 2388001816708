import React from 'react';
import { Flex, Input, Text } from '@chakra-ui/react';
import { ParameterItemProps } from './types';

const ParameterItem: React.FC<ParameterItemProps> = (props) => {
  const { parameter, isLoading, onChangeParameter } = props;

  const [readOnly, setReadOnly] = React.useState(true);
  const [value, setValue] = React.useState(parameter.value);

  const onSubmit = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key !== 'Enter') return;
    if (value === parameter.value) return;
    onChangeParameter([{ ...parameter, value }]);
    setReadOnly(true);
  };

  return (
    <Flex align='center' justify='space-between'>
      <Text
        fontSize='14px'
        fontWeight={500}
        color='txt.primary'> 
        {parameter.name} 
      </Text>
   
      <Input 
        w='100%' 
        maxW='300px' 
        value={value}
        readOnly={readOnly}
        isDisabled={isLoading}
        cursor={readOnly ? 'pointer' : 'inherit'}
        onKeyDown={onSubmit}
        onDoubleClick={(): void => setReadOnly(false)}
        onChange={(e): void => setValue(e.target.value)}
      />
        
    </Flex>
  );
};

export default ParameterItem;