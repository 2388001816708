import React from 'react';
import { Box } from '@chakra-ui/react';
import { pointSizes, StatusPointProps } from './types';

const StatusPoint: React.FC<StatusPointProps> = (props) => {
  const { size = 'default', color = '#D9D9D9' } = props;
  return (
    <Box
      bgColor={color}
      borderRadius='50%'
      width={pointSizes[size]}
      height={pointSizes[size]} />
  );
};

export default StatusPoint;

