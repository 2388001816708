// @ts-nocheck

import React from 'react';
import { IWSContext, ChanelEnum } from './types';

export const WebSocketContext = React.createContext<IWSContext>({
  getWS: () => {},
  subscribe: () => {},
  unsubscribe: () => {},
});

export const WSProvider = ({ children }: React.PropsWithChildren<IWSContext>): JSX.Element => {

  const ws = React.useRef<WebSocket>();
  const channels = React.useRef({});

  const getWS = (): WebSocket | null => ws.current || null;

  const subscribe = (channel: ChanelEnum, callback: any): void => {
    channels.current[channel] = callback;
  };

  const unsubscribe = (channel: ChanelEnum): void => {
    delete channels.current[channel];
  };
  

  const createChanel = React.useCallback(() => {
    ws.current = new WebSocket('wss://api.asend.ai/websocket');

    ws.current.onopen = (): void => {};
    ws.current.onerror = (): void => {};

    ws.current.onclose = (): void => {
      setTimeout(() => createChanel(), 1000);
    };
    
    ws.current.onmessage = (message): void => {
      const { type, command, ...data } = JSON.parse(message.data);

      if (channels.current[type]) {
        channels.current[type](data);
        if (type === ChanelEnum.FLIGHT_DATA) {
          channels.current[ChanelEnum.FLIGHT_DATA_INFO](data);
        }
      }
      if (channels.current[command]) {
        channels.current[command](data);
      }
    };
  }, []);

  React.useEffect(() => {
    createChanel();
    return () => ws.current?.close();
  }, [createChanel]);

  return (
    <WebSocketContext.Provider value={{ getWS, subscribe, unsubscribe }}>
      {children}
    </WebSocketContext.Provider>
  );
};