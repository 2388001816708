import React from 'react';
import { Flex } from '@chakra-ui/react';
import { ContentHeader, ContentInformation } from './components';
import { useSelector } from 'react-redux';
import { selectSelectedDrone } from '../../redux/extra-store/selectors';

const DronesContent: React.FC = () => {

  const selectedDrone = useSelector(selectSelectedDrone);

  if (!selectedDrone) return null;

  return (
    <Flex 
      p='32px' 
      gap='12px'
      w='calc(100% - 400px)' 
      direction='column'>

      <ContentHeader />
      <ContentInformation />
    </Flex>
  );
};

export default DronesContent;