import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GoogleMap as ReactGoogleMap } from '@react-google-maps/api';
import { setGoogleMapCenter, setGoogleMapLatitude, setGoogleMapLongitude, setGoogleMapType, setGoogleMapZoom } from './redux/actions';
import { selectGoogleMapCenter, selectGoogleMapType, selectGoogleMapZoom } from './redux/selectors';
import { GoogleMapProps } from './types';
import { Box, IconButton } from '@chakra-ui/react';
import { ReactComponent as FindMeIcon } from './assets/find-me-icon.svg';

const GoogleMap: React.FC<GoogleMapProps> = (props) => {

  const { children, ...googleMapProps } = props;

  const dispatch = useDispatch();
  const [isLoading, setLoading] = React.useState(false);
  const [_googleMap, setGoogleMap] = React.useState<google.maps.Map | null>(null);

  const zoom = useSelector(selectGoogleMapZoom);
  const center = useSelector(selectGoogleMapCenter);
  const mapTypeId = useSelector(selectGoogleMapType);

  const onLoad = React.useCallback((map: google.maps.Map) => {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    setGoogleMap(map);
  }, []);

  const findMe = () => {
    if (navigator.geolocation) {
      setLoading(true);
      navigator.geolocation.getCurrentPosition((position) => {
        const pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        dispatch(setGoogleMapCenter(pos));
        dispatch(setGoogleMapLatitude(pos.lat));
        dispatch(setGoogleMapLongitude(pos.lng));
        setLoading(false);
      }, () => setLoading(false));
    }
  };

  const onUnmount = () => setGoogleMap(null);

  function onZoomChanged(): void {
    dispatch(setGoogleMapZoom(this.zoom));
  }
  function onMapTypeIdChanged(): void {
    dispatch(setGoogleMapType(this.mapTypeId));
  }


  React.useEffect(() => {
    _googleMap?.setZoom(zoom);
  }, [zoom, _googleMap]);

  React.useEffect(() => {
    _googleMap?.setCenter(center);
  }, [center, _googleMap]);
  

  return (
    <Box 
      w='100%' 
      h='100%' 
      position='relative'>

      <IconButton 
        w='40px' 
        h='40px' 
        aria-label='find me icon' 
        position='absolute' 
        bottom={3} 
        left={1} 
        zIndex={999}
        onClick={findMe}
        isLoading={isLoading}
        icon={<FindMeIcon />} />

      <ReactGoogleMap
        zoom={zoom}
        center={center} 
        mapTypeId={mapTypeId} 

        onLoad={onLoad}
        onUnmount={onUnmount}
        onZoomChanged={onZoomChanged} 
        onMapTypeIdChanged={onMapTypeIdChanged}
        mapContainerStyle={{ width: '100%', height: '100%' }}
        {...googleMapProps}>
            
        {children}

      </ReactGoogleMap>
    </Box>
  );
};

export default GoogleMap;