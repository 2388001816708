import React from 'react';
import { useSelector } from 'react-redux';
import { selectorPopup } from '../../redux/extra-store/selectors';

import NewOrderPopup from './new-orders/new-order-popup';
import GroupOrderAssignedPopup from './group-orders/group-order-assigned-popup';
import GroupOrderReadyPopup from './group-orders/group-order-ready-popup';
import GroupOrderEnRoutePopup from './group-orders/group-order-en-route-popup';
import GroupOrderCompletedPopup from './group-orders/group-order-completed-popup';
import GroupOrderCancelledPopup from './group-orders/group-order-cancelled-popup';

import { OrderStatusEnum } from '../../models/OrderModel';

const OrdersPopup: React.FC = () => {
  const popup = useSelector(selectorPopup);

  if (popup === OrderStatusEnum.NEW) {
    return <NewOrderPopup />;
  }

  if (popup === OrderStatusEnum.ASSIGNED) {
    return <GroupOrderAssignedPopup />;
  }

  if (popup === OrderStatusEnum.READY_FOR_TAKE_OFF) {
    return <GroupOrderReadyPopup />;
  }

  if (popup === OrderStatusEnum.EN_ROUTE) {
    return <GroupOrderEnRoutePopup />;
  }

  if (popup === OrderStatusEnum.COMPLETED) {
    return <GroupOrderCompletedPopup />;
  }

  if (popup === OrderStatusEnum.CANCELLED) {
    return <GroupOrderCancelledPopup />;
  }

  return null;
};

export default OrdersPopup;