export const line = {
  return: {
    strokeOpacity: 1,
    strokeColor: '#00C4B4',
    icons: [
      { 
        icon: { 
          path: 1, 
          scale: 2, 
          strokeOpacity: 1, 
          strokeColor: '#00C4B4', 
          fillColor: '#00C4B4', 
          fillOpacity: 1 
        }, 
        repeat: '80px' 
      }
    ]
  },
  future: {
    strokeOpacity: 1,
    strokeColor: '#9E9EF3',
    icons: [
      { 
        icon: { 
          path: 1, 
          scale: 2, 
          strokeOpacity: 1, 
          strokeColor: '#A5A6F6', 
          fillColor: '#A5A6F6', 
          fillOpacity: 1 
        }, 
        repeat: '80px' 
      }
    ]
  },
  error: {},
  default: {},
};