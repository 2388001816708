import React from 'react';
import { Header } from './components/header';
import { Sidebar } from './components/sidebar';
import { useSelector } from 'react-redux';
import { selectAuthStatus } from '@src/features/auth/redux/extra-store/selectors';
import { Navigate } from 'react-router-dom';
import { Box } from '@chakra-ui/react';


interface DefaultLayoutProps {
    children: React.ReactNode;
}

const DefaultLayout: React.FC<DefaultLayoutProps> = (props) => {
  const { children } = props;

  const authStatus = useSelector(selectAuthStatus);

  if (!authStatus) return (
    <Navigate to='/auth/signIn' />
  );

  return (
    <Box minHeight='100vh'>
      <Header />
      <Box 
        pt='40px'
        width='100%'
        height='100vh'
        display='flex'>

        <Sidebar />
               
        <Box 
          overflow='auto'
          display='flex'
          bgColor='block.cloud'
          width='calc(100% - 50px)' 
          height='calc(100vh - 40px)'
        >
          {children}
        </Box>
    
      </Box>
    </Box>
  );
};

export const withDefaultLayout = <T extends Record<string, unknown>>(Component: React.FC<T>) => {
  return function withLayoutComponent(props: T): JSX.Element {
    return (
      <DefaultLayout>
        <Component {...props} />
      </DefaultLayout>
    );
  };
};