import slice from './slice';

export const { 
  fetchGeocode,
    
  setGoogleMapCenter, 
  setGoogleMapType, 
  setGoogleMapZoom, 
  setGoogleMapLatitude, 
  setGoogleMapLongitude 
} = slice.actions;