import React from 'react';
import { Flex, Skeleton } from '@chakra-ui/react';

const OrderFormLoading: React.FC = () => {
  return (
    <Flex gap='12px' direction='column'>  
      {[1,2,3,4,5,6].map((idx) => (
        <Skeleton
          key={idx}
          h='48px'
          borderRadius='8px'
          startColor='blackAlpha.300'
          endColor='blackAlpha.900'
        />
      ))}
    </Flex>
  );
};

export default OrderFormLoading;