import React from 'react';
import { useSelector } from 'react-redux';
import { routes } from '@src/shared/routes';
import { Navigate, useNavigate } from 'react-router-dom';
import { useSignUpMutation } from '../../redux/api';
import { SubmitHandler, useForm } from 'react-hook-form';
import { selectAuthStatus } from '../../redux/extra-store/selectors';
import { FormControl } from '@src/components';
import { Input, useToast, Flex } from '@chakra-ui/react';
import { AuthWrapper, AuthSubmit } from '../../components';
import { ReactComponent as ArcLogo } from 'src/assets/arc_primary_logo.svg';
import { ISignUpForm } from './types';



const SignUpPage: React.FC = () => {

  const toast = useToast();
  const navigate = useNavigate();
  const authStatus = useSelector(selectAuthStatus);
  const [signUp, { isLoading }] = useSignUpMutation();
  const { register, handleSubmit, formState: { errors } } = useForm<ISignUpForm>();

  const onSubmit: SubmitHandler<ISignUpForm> = async (form) => {
    signUp(form).unwrap()
      .then(() => {
        navigate('/auth/signUp');
      })
      .catch((e) => {
        toast({ 
          position: 'top',
          status: 'error', 
          isClosable: true, 
          title: e?.response?.data?.message || 'ERROR',  
        });
      });
  };

  if (authStatus) return <Navigate to={routes.order} />;

  return (
    <AuthWrapper> 
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex flexDirection='column' gap='12px'>

          <Flex width='100%' height='80px' justifyContent='center'>
            <ArcLogo />
          </Flex>

          <FormControl 
            isInvalid={Boolean(errors.email)} 
            helperText={errors.email?.message}>
            <Input 
              autoComplete='off' 
              placeholder='Email' 
              focusBorderColor='border.primary'
              {...register('email', { required: 'This field is required' })}
            />
          </FormControl>

          <Flex justifyContent='space-between' gap='12px'>

            <FormControl 
              isInvalid={Boolean(errors.firstname)} 
              helperText={errors.firstname?.message}>
              <Input 
                autoComplete='off' 
                placeholder='First Name' 
                focusBorderColor='border.primary'
                {...register('firstname', { required: 'This field is required' })}
              />
            </FormControl>

            <FormControl 
              isInvalid={Boolean(errors.lastname)} 
              helperText={errors.lastname?.message}>
              <Input 
                autoComplete='off' 
                placeholder='Last Name' 
                focusBorderColor='border.primary'
                {...register('lastname', { required: 'This field is required' })}
              />
            </FormControl>

          </Flex>

          <FormControl 
            isInvalid={Boolean(errors.password)} 
            helperText={errors.password?.message}>
            <Input 
              type='password'
              autoComplete='off' 
              placeholder='Password' 
              focusBorderColor='border.primary'
              {...register('password', { required: 'This field is required' })}
            />
          </FormControl>

          <FormControl 
            isInvalid={Boolean(errors.password2)} 
            helperText={errors.password2?.message}>
            <Input 
              type='password'
              autoComplete='off' 
              placeholder='Re-enter Password' 
              focusBorderColor='border.primary'
              {...register('password2', { required: 'This field is required' })}
            />
          </FormControl>

          <FormControl 
            isInvalid={Boolean(errors.phone)} 
            helperText={errors.phone?.message}>
            <Input 
              autoComplete='off' 
              placeholder='Phone Number' 
              focusBorderColor='border.primary'
              {...register('phone', { required: 'This field is required' })}
            />
          </FormControl>

          <FormControl 
            isInvalid={Boolean(errors.zip)} 
            helperText={errors.zip?.message}>
            <Input 
              autoComplete='off' 
              placeholder='Zip Code' 
              focusBorderColor='border.primary'
              {...register('zip', { required: 'This field is required' })}
            />
          </FormControl>

          <FormControl 
            isInvalid={Boolean(errors.city)} 
            helperText={errors.city?.message}>
            <Input 
              autoComplete='off' 
              placeholder='City' 
              focusBorderColor='border.primary'
              {...register('city', { required: 'This field is required' })}
            />
          </FormControl>

        </Flex>

        <AuthSubmit 
          buttonText='SIGN UP' 
          toText='SIGN IN'
          to='/auth/signIn'
          isLoading={isLoading} />
      </form>
    </AuthWrapper>
  );
};

export default SignUpPage;