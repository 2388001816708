import React from 'react';
import { Flex } from '@chakra-ui/react';

import Map from './orders-map';
import Popup from './orders-popup';
import Sidebar from './orders-sidebar';

import { withDefaultLayout } from '@src/layouts';

const OrdersPage: React.FC = () => {

  return (
    <Flex width='100%' position='relative'>
      <Sidebar />
      <Map />
      <Popup />
    </Flex>
  );
};

export default withDefaultLayout(OrdersPage);