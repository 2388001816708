import React from 'react';
import { Flex, Skeleton } from '@chakra-ui/react';
import { useSearchParams } from 'react-router-dom';
import { useDrones } from '@src/features/drones/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { useLazyGetDroneOrdersQuery } from '@src/features/drones/redux/api';
import { setSelectedDrone } from '@src/features/drones/redux/extra-store/actions';
import { selectSelectedDrone } from '@src/features/drones/redux/extra-store/selectors';
import { DroneModel } from '@src/features/drones/models';
import Drone from '../drone';

const DroneList: React.FC = () => {
  const dispatch = useDispatch();

  const { drones, isLoading } = useDrones();
  const [getDroneOrders ] = useLazyGetDroneOrdersQuery();
  const selectedDrone = useSelector(selectSelectedDrone);
  const [searchParams, setSearchParams] = useSearchParams();

  const onSelectDrone = (drone: DroneModel) => {
    if (selectedDrone?.id === drone?.id) {
      dispatch(setSelectedDrone(null));
      setSearchParams({});
      return;
    }
    dispatch(setSelectedDrone(drone));
    setSearchParams({ droneId: String(drone.id) });
    getDroneOrders({ droneId: String(drone.id) });
  };

  React.useEffect(() => {
    const droneId = searchParams.get('droneId');
    if (droneId && drones) {
      const currentDrone = drones?.find((drone) => drone?.id === Number(droneId));
      dispatch(setSelectedDrone(currentDrone || null));
      getDroneOrders({ droneId: droneId });
    }
  }, [drones]);


  if (isLoading) {
    return (
      <Flex gap='12px' direction='column'>
        {[1,2,3,4,5,6].map((idx) => (
          <Skeleton 
            key={idx}
            w='100%' 
            h='80px'
            startColor='blackAlpha.300'
            endColor='blackAlpha.900'
          />
        ))}
      </Flex>
    );
  }
  return (
    <Flex gap='12px' direction='column'>
      {drones?.map((drone) => (
        <Drone
          key={drone.id} 
          drone={drone} 
          selectedDrone={selectedDrone}
          onSelect={onSelectDrone} />
      ))}
    </Flex>
  );
};

export default DroneList;