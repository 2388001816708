import React from 'react';
import { useDispatch } from 'react-redux';
import { ReactComponent as ArcLogo } from 'src/assets/primaty_logo_without-text.svg';
import { setAuthStatus } from '@src/features/auth/redux/extra-store/actions';
import { Box, Button } from '@chakra-ui/react';

export const Header: React.FC = () => {

  const dispatch = useDispatch();

  const logout = () => {
    dispatch(setAuthStatus(true));
    localStorage.removeItem('token');
    window.location.reload();
  };
  
  return (
    <Box 
      left={0}
      right={0}
      position='fixed' 
      bgColor='block.secondary' 
      height='40px'>
      <Box>
        <Box 
          px='12px'
          display='flex' 
          alignItems='center' 
          justifyContent='space-between'>
          <Box width='32px' height='32px'>
            <ArcLogo width='100%' height='100%' />
          </Box>
          <Button 
            width='200px'
            variant='text'
            colorScheme='primaryBrand' 
            onClick={logout}> 
            Logout
          </Button>
        </Box>
      </Box>
    </Box>
  );
};