import React from 'react';


interface MaintIconProps {
    color?: string;
}
export const MaintIcon: React.FC<MaintIconProps> = (props) => {
  const { color = '#A0A0A0' } = props;
  return (
    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="carbon:license-maintenance-draft">
        <path id="Vector" d="M21.1577 13.4008L19.0931 11.3362C18.964 11.2072 18.789 11.1347 18.6065 11.1347C18.424 11.1347 18.249 11.2072 18.12 11.3362L11.7244 17.7317V20.7695H14.7622L21.1577 14.374C21.2867 14.2449 21.3592 14.0699 21.3592 13.8874C21.3592 13.7049 21.2867 13.5299 21.1577 13.4008ZM14.1924 19.3931H13.1009V18.3016L16.5419 14.8605L17.6334 15.952L14.1924 19.3931ZM18.6065 14.9789L17.515 13.8874L18.6065 12.7959L19.698 13.8874L18.6065 14.9789ZM14.4773 9.06991V7.69349H13.0031C12.939 7.44716 12.8406 7.21108 12.7106 6.99221L13.7547 5.94819L12.7815 4.97506L11.7375 6.01908C11.5186 5.88918 11.2825 5.79072 11.0362 5.72659V4.25244H9.6598V5.72659C9.41348 5.79072 9.17741 5.88918 8.95851 6.01908L7.9145 4.97506L6.94137 5.94819L7.98539 6.99221C7.85546 7.21108 7.75699 7.44716 7.6929 7.69349H6.21875V9.06991H7.6929C7.75699 9.31624 7.85546 9.55232 7.98539 9.7712L6.94137 10.8152L7.9145 11.7883L8.95851 10.7443C9.17741 10.8742 9.41348 10.9727 9.6598 11.0368V12.511H11.0362V11.0368C11.2825 10.9727 11.5186 10.8742 11.7375 10.7443L12.7815 11.7883L13.7547 10.8152L12.7106 9.7712C12.8406 9.55232 12.939 9.31624 13.0031 9.06991H14.4773ZM10.348 9.75812C10.0758 9.75812 9.80966 9.6774 9.58331 9.52615C9.35696 9.37491 9.18054 9.15994 9.07636 8.90844C8.97219 8.65693 8.94493 8.38018 8.99804 8.11318C9.05115 7.84618 9.18224 7.60092 9.37474 7.40843C9.56723 7.21593 9.81249 7.08484 10.0795 7.03173C10.3465 6.97862 10.6232 7.00588 10.8747 7.11006C11.1263 7.21423 11.3412 7.39065 11.4925 7.61701C11.6437 7.84336 11.7244 8.10947 11.7244 8.3817C11.7241 8.74664 11.5789 9.09653 11.3209 9.35458C11.0628 9.61263 10.7129 9.75776 10.348 9.75812Z" fill={color} stroke={color} strokeWidth="0.4275"/>
        <path id="Vector_2" d="M8.97443 20.7694H4.84517C4.48023 20.769 4.13035 20.6239 3.8723 20.3659C3.61425 20.1078 3.46911 19.7579 3.46875 19.393V2.87593C3.46911 2.51099 3.61425 2.16111 3.8723 1.90306C4.13035 1.64501 4.48023 1.49988 4.84517 1.49951H15.8565C16.2215 1.49988 16.5714 1.64501 16.8294 1.90306C17.0875 2.16111 17.2326 2.51099 17.233 2.87593V9.75803H15.8565V2.87593H4.84517V19.393H8.97443V20.7694Z" fill={color} stroke={color} strokeWidth="0.4275"/>
      </g>
    </svg>
  );
};

