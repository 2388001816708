import React from 'react';
import { Flex, useToast } from '@chakra-ui/react';
import { DroneMarker, GoogleMap, OrderMarker, RouteLine } from '@src/components';
import { ModifyMapProps } from './types';
import { WayPointModel } from '../../models/WayPointModel';
import { insert } from '@src/shared/helper';
import { convertWPtoPath } from '../../shared';

const Map: React.FC<ModifyMapProps> = (props) => {
  const {wayPoints, setWayPoints, missionOrders, selectedDrone } = props;

  const toast = useToast();
  const routeRef = React.useRef<any>(null);
  

  const onLoadPolyline = (polyline: google.maps.Polyline) => {
    routeRef.current = polyline;
  };

  const onAddWayPoint = (e: any) => {
    const polyline = routeRef.current as google.maps.Polyline;
    const paths = polyline.getPath().getArray().map((path) => path.toJSON());

    const wayPointIndex = (e?.edge || 0) + 1;

    const wayPoint: WayPointModel = {
      type: 'wayPoint',
      latitude: paths[wayPointIndex].lat,
      longitude: paths[wayPointIndex].lng,
    };

    setWayPoints((wps) => {
      if (paths.length > wps.length) {
        return insert(wps, wayPointIndex, wayPoint);
      }
      return wps;
    });
  };

  const onRemoveWayPoint = (e: any) => {
    const index = e.vertex || 0; // 0 is always drone

    if (wayPoints[index].type === 'wayPoint') {
      setWayPoints((wps) => wps.filter((_, i) => i!== index));
      return;
    }

    toast({
      status: 'info',
      position: 'top',
      isClosable: true, 
      title: 'You can delete only the waypoints that were added', 
    });

  };



  return (
    <Flex width='100%' minHeight='100%' pb='80px'>
      <GoogleMap>
        
        <RouteLine 
          ref={routeRef}
          status='future' 
          onLoad={onLoadPolyline}
          onMouseUp={onAddWayPoint}
          onRightClick={onRemoveWayPoint}
          options={{ editable: true, clickable: true }}
          path={wayPoints.map((wp) => convertWPtoPath(wp))} />

        <RouteLine 
          status='return' 
          path={wayPoints.slice(-2).map((wp) => convertWPtoPath(wp))} />

        {missionOrders.map((order) => (
          <OrderMarker 
            key={order.id}
            status='active'
            position={{ 
              lat: Number(order?.deliveryLocation.latitude), 
              lng: Number(order?.deliveryLocation.longitude) 
            }} />
        ))}

        
        <DroneMarker
          key={selectedDrone?.id} 
          status='active'
          position={{
            lat: Number(selectedDrone?.latitude), 
            lng: Number(selectedDrone?.longitude) 
          }} />

      </GoogleMap>
    </Flex>
  );
};

export default Map;