import React from 'react';
import { Header, DroneList } from '../../components';
import { withDefaultLayout } from '@src/layouts';
import { Flex } from '@chakra-ui/react';


const PreFlightCheckListPage: React.FC = () => {
  return (
    <React.Fragment>
      <Flex p='32px' gap='18px' direction='column'>
        <Header />
        <DroneList />
      </Flex>
    </React.Fragment>
  );
};

export default withDefaultLayout(PreFlightCheckListPage);