import { setInitialized, fetchInitialized } from './App.actions';
import { put, takeLatest } from "@redux-saga/core/effects";
import { setAuthStatus } from '@src/features/auth/redux/extra-store/actions';
import { LoadingStatus } from '@src/shared/status';


function* fetchInitializedSaga(): Generator {
  try {
    if (localStorage.getItem('token')) {
      yield put(setAuthStatus(true));
    }
    yield put(setInitialized(LoadingStatus.SUCCESS));
  } catch (e) {
    yield put(setInitialized(LoadingStatus.ERROR));
  }
}

export function* saga(): Generator {
  yield takeLatest(fetchInitialized, fetchInitializedSaga);
}