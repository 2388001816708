import { axiosBaseQuery } from '@src/service';
import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { SettingModel } from '../model';
import { IGetSwitchResponse, IGetUserResponse, ISetSettingParameterRequest, ISetSwitchRequest, ISetUserRoleRequest } from './types';


export const settingApi = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: '' }),
  reducerPath: 'settingApi',
  tagTypes: ['Switch', 'Users'],
  endpoints: (builder) => ({
    getSettings: builder.query<SettingModel[], {}>({
      query: () => ({
        url: '/orders/settings',
        method: 'GET',
      })
    }),
    setSettings: builder.mutation<{}, SettingModel[]>({
      query: (data) => ({
        url: '/orders/settings',
        method: 'POST',
        data,
      })
    }),

    getSystemSwitch: builder.query<IGetSwitchResponse, {}>({
      query: () => ({
        url: '/orders/switch',
        method: 'GET',
      }),
      providesTags: ['Switch'],
    }),

    setSystemSwitch: builder.mutation<{}, ISetSwitchRequest>({
      query: (status) => ({
        url: `/orders/switch/${status}`,
        method: 'POST',
      }),
      invalidatesTags: ['Switch'],
    }),

    getUsers: builder.query<IGetUserResponse, {}>({
      query: () => ({
        url: '/user',
        method: 'GET',
      }),
      providesTags: ['Users'],
    }),
    getUserRoles: builder.query<string[], {}>({
      query: () => ({
        url: '/user/role',
        method: 'GET',
      })
    }),
    setUserRole: builder.mutation<{}, ISetUserRoleRequest>({
      query: (data) => ({
        url: '/user/role',
        method: 'PUT',
        data
      }),
      invalidatesTags: ['Users'],
    }),

  })
});

export const { 
  useGetSettingsQuery, 
  useGetSystemSwitchQuery, 

  useSetSystemSwitchMutation,
  useSetSettingsMutation,

  useGetUsersQuery, 
  useGetUserRolesQuery,
  useSetUserRoleMutation,
} = settingApi;