import React from 'react';
import { ReactComponent as GlobalIcon } from './global-loading.svg';
import { Box, Text } from '@chakra-ui/react';

const GlobalLoading: React.FC = () => {
  return (
    <Box
      width='100%'
      minHeight='100vh'

      display='flex'
      justifyContent='center'
      alignItems='center'>

      <Box display='flex' flexDirection='column' gap='8px'>
        <GlobalIcon />
        <Text fontWeight='bold' variant='body2' color='background.paper'> Getting everythinkg ready... </Text>
      </Box>

    </Box >
  );
};

export default GlobalLoading;