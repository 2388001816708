import { CaseReducer, PayloadAction } from "@reduxjs/toolkit";
import { IOrderInitialState } from "../types";


const setCollapsed: CaseReducer<IOrderInitialState, PayloadAction<IOrderInitialState['collapsed']>> = (state, action) => {
  state.collapsed = action.payload;
};

const setSelectedNewOrder: CaseReducer<IOrderInitialState, PayloadAction<IOrderInitialState['selectedNewOrder']>> = (state, action) => {
  state.selectedNewOrder = action.payload;
};

const setSelectedGroupOrder: CaseReducer<IOrderInitialState, PayloadAction<IOrderInitialState['selectedGroupOrder']>> = (state, action) => {
  state.selectedGroupOrder = action.payload;
};

const setFormattedAddress: CaseReducer<IOrderInitialState, PayloadAction<IOrderInitialState['formattedAddress']>> = (state, action) => {
  state.formattedAddress = action.payload;
};

export default { 
  setCollapsed, 
  setSelectedNewOrder,
  setSelectedGroupOrder,
  setFormattedAddress,
};