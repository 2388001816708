import React, { useRef, useContext, useEffect, useState } from 'react';
import { iceServers } from './iceServers';
import { Flex, IconButton, useDisclosure } from '@chakra-ui/react';
import { WSRequestEnum } from '@src/context/ws-context/types';
import { WebSocketContext } from '@src/context/ws-context';
import { DroneCameraProps } from './types';

const DroneCamera: React.FC<DroneCameraProps> = (props) => {
  const { droneSerialNumber } = props;

  const videoStream = useRef<MediaStream | null>(null);
  const remoteStream = useRef<HTMLVideoElement | null>(null);
  const peerConnections = useRef<RTCPeerConnection | null>(null);

  const { isOpen, onClose, onOpen } = useDisclosure();
  const [isLoading, setLoading] = useState<boolean>(false);

  const { getWS, subscribe, unsubscribe } = useContext(WebSocketContext);

  const onStartCapture = async (): Promise<void> => {
    peerConnections.current = new RTCPeerConnection({ iceServers });

    const videoConfig = { audio: false, video: { width: 1280, height: 720 } };
    videoStream.current = await navigator.mediaDevices.getUserMedia(videoConfig);

    videoStream.current.getTracks().forEach((track) => {
      peerConnections.current?.addTrack(track, videoStream.current as MediaStream);
    });
    peerConnections.current.ontrack = ({ streams: [remote] }): void => {
      if (remoteStream.current) {
        remoteStream.current.srcObject = remote;
      }
    };
  };

  const onCreateOffer = async (): Promise<void> => {
    const webSocket = getWS();
    if (peerConnections.current) {
      peerConnections.current.onicecandidate = async (event): Promise<void> => {
        if (event.candidate) {
          const payload = {
            type: WSRequestEnum.WEBRTC,
            command: 'ice_candidate',
            data: JSON.stringify(event.candidate),
            serialNumber: droneSerialNumber,
          };

          webSocket.send(JSON.stringify(payload));
        }
      };

      const offer = await peerConnections.current.createOffer();
      await peerConnections.current.setLocalDescription(offer);

      const payload = {
        type: WSRequestEnum.WEBRTC,
        command: 'offer',
        data: offer.sdp,
        serialNumber: droneSerialNumber,
      };

      webSocket.send(JSON.stringify(payload));
    }
  };

  const onStartVideo = async (): Promise<void> => {
    onOpen();
    setLoading(true);
    await onStartCapture();
    await onCreateOffer();
    setLoading(false);
  };

  const onStopVideo = async (): Promise<void> => {
    const webSocket = getWS();

    const payload = {
      type: WSRequestEnum.WEBRTC,
      command: 'stop',
      data: null,
      serialNumber: droneSerialNumber,
    };
    webSocket.send(JSON.stringify(payload));

    videoStream.current?.getTracks().forEach((track) => track.stop());
    peerConnections.current?.close();
    onClose();
  };

  const handleAnswer = async (answer: RTCSessionDescriptionInit): Promise<void> => {
    if (peerConnections.current) {
      await peerConnections.current.setRemoteDescription(new RTCSessionDescription(answer));
    }
  };

  const handleIceCandidate = (remoteCandidate: RTCIceCandidateInit): void => {
    const candidate = new RTCIceCandidate(remoteCandidate);
    peerConnections.current?.addIceCandidate(candidate);
  };

  useEffect(() => {
    const answerSubscription = (message: any) => {
      handleAnswer({ type: 'answer', sdp: message.data });
    };
    const iceCandidateSubscription = (message: any) => {
      handleIceCandidate(JSON.parse(message.data));
    };

    subscribe('answer', answerSubscription);
    subscribe('ice_candidate', iceCandidateSubscription);

    return () => {
      unsubscribe('answer', answerSubscription);
      unsubscribe('ice_candidate', iceCandidateSubscription);
    };
  }, [subscribe, unsubscribe]);

  if (isOpen) {
    return (
      <Flex 
        p='12px'
        w='100%' 
        direction='column'
        onClick={onClose}>
        <video
          autoPlay 
          playsInline
          width='100%' 
          height='300px'
          ref={remoteStream}
        />
        <IconButton 
          mt='12px'
          mx='auto'
          width='50px'
          height='50px' 
          colorScheme='red'
          aria-label='Stop Video'
          onClick={onStopVideo} 
        />
      </Flex>
    );
  }
  return (
    <IconButton
      mx='auto'
      width='50px'
      height='50px'
      variant='brand'
      aria-label='Start Video'
      isLoading={isLoading}
      onClick={onStartVideo}
    />
  );
};

export default DroneCamera;
