import React from 'react';
import { NavLink } from 'react-router-dom';
import { Flex, Button } from '@chakra-ui/react';
import { AuthSubmitProps } from './types';


const AuthSubmit: React.FC<AuthSubmitProps> = (props) => {
  const { buttonText, toText, to, isLoading } = props;

  return (
    <Flex 
      gap='8px'
      width='100%'
      maxWidth='300px'
      margin='12px auto' 
      direction='column'>

      <Button type='submit' variant='brand' isLoading={isLoading}> {buttonText} </Button>
      <NavLink to={to}>
        <Button variant='text' isLoading={isLoading}> {toText} </Button>
      </NavLink>

    </Flex> 
  );
};

export default AuthSubmit;