import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { BatteryHealth } from '@src/components';
import { CardWrapper } from '../components';
import { useDispatch, useSelector } from 'react-redux';

import { setSelectedGroupOrder, setSelectedNewOrder } from '@src/features/orders/redux/extra-store/actions';
import { selectorSelectedGroupOrder } from '@src/features/orders/redux/extra-store/selectors';

import { OrderGroupModel, OrderStatusEnum } from '@src/features/orders/models/OrderModel';
import { WebSocketContext } from '@src/context/ws-context';
import { WSRequestEnum } from '@src/context/ws-context/types';
import { setGoogleMapCenter } from '@src/components/google-map/redux/actions';


interface GroupOrderItemProps {
  order: OrderGroupModel;
  status: OrderStatusEnum;
}
const GroupOrderItem: React.FC<GroupOrderItemProps> = (props) => {

  const { order, status } = props;

  const dispatch = useDispatch();
  const { getWS } = React.useContext(WebSocketContext);
  const selected = useSelector(selectorSelectedGroupOrder);


  const subscribe = (): void => {
    const ws = getWS();
    if (ws && ws.readyState === WebSocket.OPEN) {
      if (selected?.status === OrderStatusEnum.EN_ROUTE) {
        const payload = { type: WSRequestEnum.STOP_FLIGHT_DATA };
        ws.send(JSON.stringify(payload));
      }
      
      if (status === OrderStatusEnum.EN_ROUTE) {
        const payload = { type: WSRequestEnum.GET_FLIGHT_DATA, data: { missionId: order.missionId } };
        ws.send(JSON.stringify(payload));
      }
    }
  };

  const onSelect = (): void => {

    subscribe();

    dispatch(setSelectedNewOrder(null));
    dispatch(setSelectedGroupOrder({ ...order, status }));

    const mapCenterPayload = { lat: Number(order.drone.latitude), lng: Number(order.drone.longitude) };
    dispatch(setGoogleMapCenter(mapCenterPayload));

  };

  return (
    <CardWrapper 
      gap='8px'
      display='flex' 
      flexDirection='column'
      onClick={onSelect}
      active={selected?.missionId === order.missionId}>
      <Flex gap='6px' align='center' justify='space-between'>
        <Text 
          fontSize='14px' 
          fontWeight={600} 
          color='text.snow' 
          whiteSpace='nowrap'> 
          {order.drone.name} 
        </Text>
        <Text 
          fontSize='14px' 
          color='text.secondary' 
          noOfLines={1}> 
          {order.drone.serialNumber} 
        </Text>
      </Flex>
      <Flex gap='6px' align='center' justify='space-between'>
        <Text 
          fontSize='12px' 
          color='text.secondary'
          fontWeight={600}> 
          {order.drone.status} 
        </Text>
        <BatteryHealth percent={order.drone.batteryHealth}/>
      </Flex>
    </CardWrapper>
  );
};

export default GroupOrderItem;