import React from 'react';
import { Flex } from '@chakra-ui/react';
import { OrderItem } from '../../components';
import { EmptyContent } from '@src/components';
import { SectionLoading } from '@src/features/orders/components';
import { useSelectOrder } from '@src/features/orders/hooks';
import { OrdersSectionProps } from './types';


const OrdersSection: React.FC<OrdersSectionProps> = (props) => {
  const { type, isLoading, orders , onToggleOrder, onDragDropOrder } = props;

  const { selectedOrder, onSelectOrder } = useSelectOrder();

  if (isLoading) {
    return <SectionLoading />;
  }

  if (orders.length < 1) {
    return <EmptyContent> No orders </EmptyContent>;
  }

  return (
    <Flex gap='10px' flexWrap='wrap'> 
      {orders.map((order, idx) => (
        <OrderItem
          type={type}
          itemIdx={idx}
          key={order.id}

          selected={selectedOrder}
          onSelectOrder={onSelectOrder}

          order={order}
          onToggleOrder={onToggleOrder}
          onDragDropOrder={onDragDropOrder}
        /> 
      ))} 
    </Flex>
  );
};

export default OrdersSection;