import React from 'react';
import { Flex } from '@chakra-ui/react';
import { getGeocode } from 'use-places-autocomplete';
import { DroneMarker, GoogleMap, OrderMarker } from '@src/components';
import { useDispatch, useSelector } from 'react-redux';
import { setGoogleMapLatitude, setGoogleMapLongitude } from '@src/components/google-map/redux/actions';
import { selectGoogleMapLatitude, selectGoogleMapLongitude } from '@src/components/google-map/redux/selectors';
import { setFormattedAddress } from '../../redux/extra-store/actions';
import { useDrones } from '@src/features/drones/hooks';
import { DroneStatusEnum } from '@src/features/drones/models';

const OrderMap: React.FC = () => {
  const dispatch = useDispatch();
  const lat = useSelector(selectGoogleMapLatitude);
  const lng = useSelector(selectGoogleMapLongitude);
  const { drones } = useDrones(DroneStatusEnum.IDLE);

  const onChangeAddress = async (e: google.maps.MapMouseEvent) => {
    const lat = e?.latLng?.lat();
    const lng = e?.latLng?.lng();

    if (lat && lng) {
      const payload = { location: { lat, lng } };
      const addresses = await getGeocode(payload);
      const [{ formatted_address }] = addresses;

      dispatch(setGoogleMapLatitude(lat));
      dispatch(setGoogleMapLongitude(lng));
      dispatch(setFormattedAddress(formatted_address));
    }
  };
    
  return (
    <Flex w='100%' minH='100%' pb='80px'>
      <GoogleMap onDblClick={onChangeAddress}>
        <OrderMarker 
          draggable
          status='active'
          position={{ lat, lng }}
          onDragEnd={onChangeAddress} />
        {drones?.map((drone) => (
          <DroneMarker
            key={drone.id}
            status='active'
            position={{ lat: Number(drone.latitude), lng: Number(drone.longitude) }} />
        ))}
      </GoogleMap>
    </Flex>
  );
};

export default OrderMap;