import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ChecklistPage from './pages/checklist-page';

const ChecklistFeature: React.FC = () => {
  return (
    <Routes>
      <Route path='/' element={<ChecklistPage /> } />
    </Routes>
  );
};

export default ChecklistFeature;