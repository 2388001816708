import { IOrderInitialState } from '../types';
import { OrderStatusEnum } from '../../models/OrderModel';
import { LoadingStatus } from '@src/shared/status';

const initialState: IOrderInitialState = {
  collapsed: [ OrderStatusEnum.NEW ],
  isLoading: LoadingStatus.NEVER,

  popup: null,
  selectedNewOrder: null,
  selectedGroupOrder: null,
  formattedAddress: '',
};

export default initialState;