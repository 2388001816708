import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { IDragItem, UseDragDropProps } from './types';
import { Identifier } from 'dnd-core';

const DRAG_AND_DROP_KEY = 'DRAG_AND_DROP_KEY';

const useDragDrop = (props: UseDragDropProps) => {
  const { order, itemIdx, onDragDropOrder } = props;
  const ref = React.useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop<IDragItem, void, { handlerId: Identifier | null }>({
    accept: DRAG_AND_DROP_KEY,

    collect(monitor) {
      return { handlerId: monitor.getHandlerId() };
    },

    hover(item) {
      if (!ref.current) return;

      const dragIndex = item.index;
      const hoverIndex = itemIdx;

      if (dragIndex === hoverIndex) return;

      onDragDropOrder && onDragDropOrder(dragIndex, hoverIndex);

      item.index = hoverIndex;
    }
  });

  const [{ isDragging }, drag] = useDrag({
    type: DRAG_AND_DROP_KEY,
    item: () => ({ id: order.id, index: itemIdx }),
    collect: (monitor) => ({ isDragging: monitor.isDragging() })
  });

  drag(drop(ref));

  return {
    ref,
    handlerId,
    opacity: isDragging ? 0.3 : 1,
  };
};

export default useDragDrop;