import React from 'react';
import { Flex } from '@chakra-ui/react';
import { SectionLoading } from '@src/features/orders/components';
import { DroneItem } from '../../components';

import { DronesSectionProps } from './types';


const DronesSection: React.FC<DronesSectionProps> = (props) => {
  const { drone, isLoading } = props;

  if (isLoading) return <SectionLoading />;
 
  return (
    <Flex gap='8px' direction='column'>
      <DroneItem drone={drone} />
    </Flex>
  );
};

export default DronesSection;