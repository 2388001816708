import React from 'react';
import { Flex, Skeleton } from '@chakra-ui/react';

import { SectionLoadingProps } from './types';

const SectionLoading: React.FC<SectionLoadingProps> = (props) => {
  const { count = 4 } = props;
  return (
    <Flex gap='8px' direction='column'>
      {Array(count).fill(1).map((_, idx) => (
        <Skeleton
          key={idx}
          width='100%'
          height='80px'
          startColor='blackAlpha.300'
          endColor='blackAlpha.900'  />
      ))}
    </Flex>
  );
};

export default SectionLoading;