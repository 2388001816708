import React from 'react';
import { Flex, useToast } from '@chakra-ui/react';
import { withDefaultLayout } from '@src/layouts';
import { useNavigate, useParams } from 'react-router-dom';

import Map from './modify-map';
import Sidebar from './modify-sidebar';
import { useMissionOrder } from '../../hooks';
import { TabActionButtons } from '../../components';
import { useSelector } from 'react-redux';
import { selectSelectedDrone } from '@src/features/drones/redux/extra-store/selectors';
import { useModifyRouteMutation } from '../../redux/api';
import { WayPointModel } from '../../models/WayPointModel';
import { convertDroneToWP, convertOrderToWP } from '../../shared';


const ModifyRoutePage: React.FC = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const { missionId } = useParams();
  const [modifyRoute, { isLoading }] = useModifyRouteMutation();
  const { missionOrders, isMissionOrdersLoading, onDragDropMissionOrder } = useMissionOrder();

  const selectedDrone = useSelector(selectSelectedDrone);
  const [wayPoints, setWayPoints] = React.useState<WayPointModel[]>([]);

  const goForth = () => {
    if (!missionId || !wayPoints.length) {
      toast({
        status: 'error',
        position: 'top',
        isClosable: true, 
        title: 'Something went wrong', 
      });
      return;
    };

    modifyRoute({ missionId, waypoints: wayPoints }).unwrap().then(() => {
      goBack();
    });
  };
  const goBack = () => navigate('/app/orders');

  React.useEffect(() => {
    if (selectedDrone) {
      setWayPoints([
        convertDroneToWP(selectedDrone), 
        ...missionOrders.map((order) => convertOrderToWP(order)),
        convertDroneToWP(selectedDrone),
      ]);
    }
  }, [missionOrders, selectedDrone]);

  return (
    <Flex width='100%'>
      <Sidebar 
        selectedDrone={selectedDrone}
        missionOrders={missionOrders}
        isMissionOrdersLoading={isMissionOrdersLoading}
        onDragDropMissionOrder={onDragDropMissionOrder}
      />

      <Map 
        wayPoints={wayPoints}
        missionOrders={missionOrders} 
        selectedDrone={selectedDrone}
        setWayPoints={setWayPoints}
      />

      <TabActionButtons
        goBack={goBack}
        goBackText='CANCEL'

        goForth={goForth} 
        goForthText='MODIFY ROUTE'
        
        isLoading={isLoading} />
    </Flex>
  );
};

export default withDefaultLayout(ModifyRoutePage);