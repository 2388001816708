import React from 'react';
import { DroneIcon } from '@src/components';
import { Flex, Grid, Skeleton, Text } from '@chakra-ui/react';
import { useDrones } from '@src/features/drones/hooks';
import { colors } from './data';


const DroneList: React.FC = () => {

  const { drones, isLoading } = useDrones();

  if (isLoading) {
    return (
      <Grid gap='32px' gridTemplateColumns='repeat(8, 1fr)'>
        {[1,2,3,4].map((idx) => (
          <Skeleton 
            key={idx}
            w='150px'
            h='100px'
            borderRadius='8px'
            startColor='blackAlpha.300'
            endColor='blackAlpha.900'
          />
        ))}
      </Grid>
    );
  }

  return (
    <Grid gap='32px' gridTemplateColumns='repeat(8, 1fr)'>
      {drones?.map((drone) => (
        <Flex 
          key={drone.id} 
          gap='4px'
          align='center' 
          direction='column'>
          <DroneIcon w='42px' h='42px' iconColor={colors[drone.status] || '#666666'} />
          <Text 
            fontSize='12px' 
            fontWeight={700}
            textAlign='center'
            color={colors[drone.status] || 'text.secondary'}> 
            {drone.name} ({drone.serialNumber}) 
          </Text>

          <Text 
            fontSize='12px' 
            color={colors[drone.status] || 'text.secondary'}> 
            {drone.status}
          </Text>
        </Flex>
      ))}
    </Grid>
  );
};

export default DroneList;