import React from 'react';
import { Route, Routes } from 'react-router-dom';

import SignInPage from './pages/sign-in-page';
import SignUpPage from './pages/sign-up-page';
import ActivatePage from './pages/activate-page';


const AuthFeature: React.FC = () => {
  return (
    <Routes>
      <Route path='/signIn' element={<SignInPage />} />
      <Route path='/signUp' element={ <SignUpPage /> } />
      <Route path='/activate' element={ <ActivatePage /> } />
    </Routes>
  );
};

export default AuthFeature;