import React from "react";
import { Flex, Text } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { selectorSelectedGroupOrder } from "@src/features/orders/redux/extra-store/selectors";
import { OrderModel } from "@src/features/orders/models/OrderModel";

interface PopupOrdersListProps {
  onClick: (groupOrder: OrderModel) => void;
}

const PopupOrdersList: React.FC<PopupOrdersListProps> = (props) => {
  const { onClick } = props;

  const selected = useSelector(selectorSelectedGroupOrder);

  return (
    <Flex 
      my='18px' 
      gap='10px'
      wrap='wrap'>

      {selected?.orders.map((order) => (
        <Flex 
          key={`${order.id}_${order.status}`} 
          p='12px' 
          w='100%'
          maxW='183px'
          cursor='pointer'
          bg='block.blackAlpha'
          direction='column'
          borderRadius='8px'
          onClick={(): void => onClick(order)}>

          <Text color='text.snow' fontSize='14px' noOfLines={2}> #{order.id} {order.clientName} </Text>
          <Text color='text.secondary' fontSize='12px'> {order.deliveryLocation.address} </Text>

        </Flex>
      ))}
    </Flex>
  );
};

export default PopupOrdersList;