import React from 'react';
import { useSelector } from 'react-redux';
import { PopupOrdersList, PopupWrapper, TakeDroneInfo } from '../components';
import GroupOrderPopupActions from './group-order-popup-actions';
import { selectorSelectedGroupOrder } from '@src/features/orders/redux/extra-store/selectors';
import { Button, Divider, useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { OrderModel } from '@src/features/orders/models/OrderModel';
import { WebSocketContext } from '@src/context/ws-context';
import { WSRequestEnum } from '@src/context/ws-context/types';
import { useTakeOffMutation } from '@src/features/orders/redux/api';

const GroupOrderReadyPopup: React.FC = () => {

  const toast = useToast();
  const navigate = useNavigate();
  const [takeOff, { isLoading }] = useTakeOffMutation();

  const [confirmed, setConfirmed] = React.useState(false);

  const { getWS } = React.useContext(WebSocketContext);
  const selected = useSelector(selectorSelectedGroupOrder);

  const onTakeOff = async (): Promise<void> => {
    if (selected && selected.missionId) {
      takeOff({ missionId: selected.missionId }).unwrap()
        .then(() => {
          toast({ 
            status: 'success', 
            isClosable: true, 
            title: 'Your request was submitted',  
          });
          const ws = getWS();
          const payload = { 
            type: WSRequestEnum.GET_FLIGHT_DATA, 
            data: { missionId: selected!.missionId } 
          };
          ws.send(JSON.stringify(payload));
        })
        .catch(() => {
          toast({ 
            status: 'error', 
            isClosable: true, 
            title: 'Something went wrong',  
          });  
        });
    }
  }; 

  const redirectToUpdate = (order: OrderModel): void => {
    navigate(`/app/orders/update/${order.id}`);
  };

  return (
    <PopupWrapper 
      title={`Drone - (${selected?.drone.serialNumber})`}> 
      
      <PopupOrdersList onClick={redirectToUpdate} />

      <GroupOrderPopupActions />

      <Divider borderColor='gray' my='18px' />

      {!confirmed && <TakeDroneInfo />}

      {confirmed 
        ? <Button 
          mt='18px' 
          variant='brand'
          onClick={onTakeOff}
          isLoading={isLoading}>
          TAKE OFF
        </Button> 
        :<Button
          mt='18px'
          onClick={() => setConfirmed(true)}
          variant='brand'
          isLoading={isLoading}> 
          CONFIRM
        </Button>}


    </PopupWrapper>
  );
};

export default GroupOrderReadyPopup;