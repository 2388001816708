import { createSelector } from "@reduxjs/toolkit";

// types
import { RootState } from "@src/store";
import { IAuthInitialState } from "../types";

const AuthReducer = (state: RootState): IAuthInitialState => state.AuthReducer;
const AuthState = createSelector([AuthReducer], (state) => state);

export const selectAuthStatus = createSelector([AuthState], (state) => state.authStatus);

