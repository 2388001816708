import React from 'react';
import { WebSocketContext } from "@src/context/ws-context";
import { useGetDronesQuery } from "../../redux/api";
import { ChanelEnum } from '@src/context/ws-context/types';
import { DroneStatusEnum } from '../../models';

const useDrones = (status?: DroneStatusEnum) => {
  const { data: drones, isLoading, refetch } = useGetDronesQuery({});
  const { subscribe, unsubscribe } = React.useContext(WebSocketContext);

  const filteredDrones = React.useMemo(() => {
    if (status) {
      return drones?.data?.filter((drone) => drone.status === status) || [];
    }
    return drones?.data || [];
  }, [status, drones]);

  React.useEffect(() => {
    subscribe(ChanelEnum.UPDATE_DRONES, () => {
      refetch();
    });
    return (): void => unsubscribe(ChanelEnum.UPDATE_DRONES);
  }, [subscribe, unsubscribe]);

  return {
    drones: filteredDrones,
    isLoading,
  };
};

export default useDrones;

