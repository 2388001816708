import React from 'react';
import { BatteryHealth } from '@src/components';
import { Checkbox, Flex, Text } from '@chakra-ui/react';
import { CardWrapper } from '@src/features/orders/components';

import { DroneItemProps } from './types';


const DroneItem: React.FC<DroneItemProps> = (props) => {
  const { selected, drone, onSelectDrone } = props;

  return (
    <CardWrapper 
      isSelected={selected?.id === drone.id} 
      onClick={() => onSelectDrone(drone)}>

      <Flex gap='8px' align='center' fontSize='14px'>
        <Checkbox 
          pointerEvents='none'
          colorScheme='primaryBrand' 
          isChecked={selected?.id === drone.id}
        />
        <Text color='white' fontWeight={700}> {drone?.name} </Text>
        <Text color='txt.secondary'> {drone?.serialNumber} </Text>
      </Flex>

      <Flex justifyContent='space-between'>
        <Text color='white' fontWeight={700}> {drone?.status} </Text>
        <BatteryHealth percent={drone?.batteryHealth} />
      </Flex>

    </CardWrapper>
  );
};

export default DroneItem;