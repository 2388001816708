import React from 'react';
import { Button, Flex } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { FormActionsProps } from './types';


const FormActions: React.FC<FormActionsProps> = (props) => {
  const { submitText, isLoading } = props;
  const navigate = useNavigate();

  const redirectToOrdersPage = () => navigate('/app/orders');

  return (
    <Flex
      p='12px' 
      h='80px'
      w='100%' 
      gap='12px'
      align='center'
      position='fixed'
      left={0} 
      bottom={0} 
      bg='linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), #121212'>

      <Button 
        w='200px' 
        type='submit'
        fontSize='12px'
        colorScheme='primaryBrand' 
        fontWeight={700}
        isLoading={isLoading}> 
        {submitText || 'SUBMIT'}
      </Button>

      <Button 
        w='200px' 
        fontSize='12px'
        fontWeight={700} 
        onClick={redirectToOrdersPage}
        isLoading={isLoading}> 
        CANCEL 
      </Button>
    </Flex>
  );
};

export default FormActions;