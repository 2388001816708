import React from 'react';
import { BatteryHealthProps } from './types';

const BatteryHealth: React.FC<BatteryHealthProps> = (props) => {
  const { percent } = props;

  return (
    <svg width="33" height="20" viewBox="0 0 33 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="6" y="6" width={`${percent || 0}%`} height="8" rx="1" fill="#275028" />
      <rect x="5" y="5" width="24" height="10" rx="2" stroke="white" strokeOpacity="0.6" strokeWidth="2" />
      <path d="M30 7H31C31.5523 7 32 7.44772 32 8V12C32 12.5523 31.5523 13 31 13H30V7Z" fill="white" fillOpacity="0.6" />
    </svg>
  );
};

export default BatteryHealth;

