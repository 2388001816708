import { OrderModel } from "../../models/OrderModel";
import { useDispatch, useSelector } from "react-redux";
import { selectSelectedNewOrder } from "../../redux/extra-store/selectors";
import { setSelectedNewOrder } from "../../redux/extra-store/actions";
import { setGoogleMapCenter, setGoogleMapLatitude, setGoogleMapLongitude } from "@src/components/google-map/redux/actions";

const useSelectOrder = () => {
  const dispatch = useDispatch();
  const selected = useSelector(selectSelectedNewOrder);

  const onSelect = (order: OrderModel) => {
    if (selected?.id === order.id) {
      dispatch(setSelectedNewOrder(null));
      return;
    }

    dispatch(setSelectedNewOrder(order));
    const { deliveryLocation: { latitude, longitude } } = order;
    dispatch(setGoogleMapLatitude(Number(latitude)));
    dispatch(setGoogleMapLongitude(Number(longitude)));
    dispatch(setGoogleMapCenter({ lat: Number(latitude), lng: Number(longitude) }));
  };

  return {
    selectedOrder: selected,
    onSelectOrder: onSelect,
  };

};

export default useSelectOrder;