import { axiosBaseQuery } from "@src/service";
import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { 
  IDeleteDroneRequest, 
  IGetDroneOrdersRequest, 
  IGetDroneOrdersResponse, 
  IGetDronesResponse, 
  IRegistrationDroneRequest, 
  IResetDroneRequest 
} from "./types";

export const droneApi = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: '' }),
  reducerPath: 'droneApi',
  tagTypes: ['DroneOrders'],
  endpoints: (builder) => ({
    getDrones: builder.query<IGetDronesResponse, {}>({
      query: () => ({
        url: '/drone',
        method: 'GET',
      }),
    }),
    getDroneOrders: builder.query<IGetDroneOrdersResponse, IGetDroneOrdersRequest>({
      query: (data) => ({
        url: `/drone/${data.droneId}/orders`,
        method: 'GET',
      }),
      providesTags: ['DroneOrders'],
      keepUnusedDataFor: 60,
    }),
    deleteDrone: builder.mutation<{}, IDeleteDroneRequest>({
      query: (data) => ({
        url: `/drone/archive/${data.id}`,
        method: 'PUT'
      }),
    }),
    registerDrone: builder.mutation<{}, IRegistrationDroneRequest>({
      query: (data) => ({
        url: 'drone/activate',
        method: 'PUT',
        data,
      }),
    }),
    resetPart: builder.mutation<{}, IResetDroneRequest>({
      query: (data) => ({
        url: `drone/${data.id}/${data.part}/reset`,
        method: 'PUT',
      }),
      invalidatesTags: ['DroneOrders']
    })
  })
});

export const { 
  useGetDronesQuery, 
  useLazyGetDroneOrdersQuery,

  useDeleteDroneMutation, 
  useRegisterDroneMutation,
  useResetPartMutation,
} 
= droneApi;
