import React from 'react';
import { CardWrapper } from '../components';
import { Flex, Text } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedNewOrder } from '@src/features/orders/redux/extra-store/selectors';
import { setSelectedGroupOrder, setSelectedNewOrder } from '@src/features/orders/redux/extra-store/actions';
import { OrderModel } from '@src/features/orders/models/OrderModel';
import { setGoogleMapCenter } from '@src/components/google-map/redux/actions';


interface NewOrderItemProps {
  order: OrderModel;
}

const NewOrderItem: React.FC<NewOrderItemProps> = (props) => {
  const { order } = props;

  const dispatch = useDispatch();
  const selected = useSelector(selectSelectedNewOrder);

  const onSelect = (): void => {
    dispatch(setSelectedGroupOrder(null));
    dispatch(setSelectedNewOrder(order));
    
    const { deliveryLocation: { latitude, longitude } } = order;
    dispatch(setGoogleMapCenter({ lat: Number(latitude), lng: Number(longitude) }));
  };

  return (
    <CardWrapper  
      active={order.id === selected?.id}
      onClick={onSelect}>
      <Flex direction='column'>
        <Text color='text.snow' fontSize='14px' noOfLines={2}> #{order.id} {order.clientName} </Text>
        <Text color='text.secondary' fontSize='12px' noOfLines={2}> {order.deliveryLocation.address} </Text>
      </Flex>
    </CardWrapper>
  );
};

export default NewOrderItem;