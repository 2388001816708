import React from 'react';
import { Flex } from '@chakra-ui/react';
import { EmptyContentProps } from './types';

const EmptyContent: React.FC<EmptyContentProps> = (props) => {
  const { children } = props;
  
  return (
    <Flex 
      w='100%' 
      h='50px'
      color='white'  
      align='center' 
      justify='center'
      borderRadius='8px'
      bg='linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), #121212'>
      {children}
    </Flex>
  );
};

export default EmptyContent;