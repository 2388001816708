import React from 'react';
import { useSelector } from 'react-redux';
import { selectorSelectedGroupOrder } from '../../redux/extra-store/selectors';
import { RouteLine, OrderMarker, DroneMarker, GoogleMap } from '@src/components';
import { FlightIndicator, MainIndicator } from './components';
import { getPathsFromGroupOrder } from './utils';
import { useDrones } from '@src/features/drones/hooks';
import { DroneStatusEnum } from '@src/features/drones/models';
import { Box } from '@chakra-ui/react';
import { OrderStatusEnum } from '../../models/OrderModel';

const OrdersMap: React.FC = () => {

  const { drones } = useDrones(DroneStatusEnum.IDLE);
  const selected = useSelector(selectorSelectedGroupOrder);

  const path = React.useMemo(() => getPathsFromGroupOrder(selected), [selected]);
    
  return (
    <Box width='100%' height='100%' position='relative'>

      {selected?.status === OrderStatusEnum.EN_ROUTE && <FlightIndicator />}

      <GoogleMap>

        <RouteLine path={path} status='future' />

        <MainIndicator />

        {selected?.orders?.map((order) => ( 
          <OrderMarker 
            key={order.id}
            status='active'
            position={{ 
              lat: Number(order.deliveryLocation.latitude),
              lng: Number(order.deliveryLocation.longitude),
            }}/> 
        ))}

        {drones?.map((drone) => (
          <DroneMarker
            key={drone.id}
            status='active'
            position={{ lat: Number(drone.latitude), lng: Number(drone.longitude) }} />
        ))}

      </GoogleMap>
    </Box>
  );
};

export default OrdersMap;