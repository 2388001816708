import React from 'react';
import chakraTheme from '@src/styles/chakra';
import { Route, Routes } from 'react-router-dom';
import SettingPage from './pages/settings-page';

const SettingFeature: React.FC = () => {
  return (
    <Routes>
      <Route path='/' element={<SettingPage />} />
    </Routes>
  );
};

export default SettingFeature;