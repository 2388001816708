export const PRIMARY = '#FFFFFF';
export const SECONDARY = '#A7A7A7';

export const PRIMARY_BRAND = '#9E9EF3';

const colors = {

  primaryBrand: {
    50: '#D1D1FB',
    100: '#B4B4F9',
    200: '#A0A0F7',
    300: '#8C8CF5',
    400: '#7F7FF3',
    500: PRIMARY_BRAND,
    600: '#6969EF',
    700: '#5C5CEC',
    800: '#5050E8',
    900: '#3737E3',
  },

  txt: {
    primary: PRIMARY,
    primaryBrand: PRIMARY_BRAND,

    secondary: SECONDARY,
  },

  // --- old style
  block: {
    primary: '#000000',
    secondary: '#272727',
    cloud: '#121212',
    alabaster: '#1E1E1E',
    blackAlpha: '#0000005C',
  },
  text: {
    primary: '#9E9EF3',
    secondary: '#FFFFFF99',
    snow: '#FFFFFF',
    error: '#E53E3E',
  },
  border: {
    primary: '#9E9EF3',
    primaryBrand: PRIMARY_BRAND,
    secondary: '#FFFFFF99',
    
    error: '#E53E3E',
  },
  button: {
    primary: '#9E9EF3',
  }
};

export default colors;
