export interface IWSContext {
    getWS: any;
	subscribe: any;
	unsubscribe: any;
}

export enum WSRequestEnum {
	WEBRTC = 'WEBRTC',
    GET_FLIGHT_DATA = 'GET_FLIGHT_DATA',
	STOP_FLIGHT_DATA = 'STOP_FLIGHT_DATA',
}

export enum ChanelEnum {
    FLIGHT_DATA = 'FLIGHT_DATA',
	FLIGHT_DATA_INFO = 'FLIGHT_DATA_INFO',
	ORDER_STATUS = 'ORDER_STATUS',
	UPDATE_ORDERS = 'UPDATE_ORDERS',
	UPDATE_DRONES = 'UPDATE_DRONES',
	TELEMETRY = 'telemetry',
}