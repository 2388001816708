import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const baseStyle = defineStyle({
  width: '100%',
  fontSize: '14px',
  fontWeight: 500,
  borderRadius: '4px',
  transition: 'transform 0.3s ease-out, background-color 0.3s ease-out'
});

const sizes = {
  small: defineStyle({
    fontSize: '12px',
    height: '30px',
  }),
  medium: defineStyle({
    fontSize: '14px',
    height: '35px',
  }),
  large: defineStyle({
    fontSize: '16px',
    height: '40px',
  })
};

const variants = {
  brand: defineStyle({
    color: '#000000',
    backgroundColor: '#9E9EF3',
    _hover: {
      backgroundColor: '#7D7DF0',
    },
    _active: {
      backgroundColor: '#6666CC',
    }
  }),
  text: defineStyle({
    color: '#FFFFFFDE',
    backgroundColor: 'transparent',
    _hover: {
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
    },
    _active: {
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
    }
  }),
};


export const buttonTheme = defineStyleConfig({
  baseStyle,
  variants,
  sizes,

  defaultProps: {
    size: 'medium',
  }
});