import React from 'react';
import { Flex } from '@chakra-ui/react';
import { CardWrapperProps } from './types';

const CardWrapper = React.forwardRef<HTMLDivElement, CardWrapperProps>((props, ref) => {
  const { isSelected, children, ...flexProps } = props;
  return (
    <Flex 
      ref={ref}
      p='12px' 
      gap='8px'
      width='100%' 
      cursor='pointer'
      borderRadius='8px'
      border='1px solid'
      bg='block.alabaster'
      direction='column'
      borderColor={isSelected ? 'border.primary' : 'transparent'}
      {...flexProps}>
      {children}
    </Flex>
  );
});

CardWrapper.displayName = 'CardWrapper';
export default CardWrapper;