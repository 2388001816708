import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setSelectedDrone } from '@src/features/drones/redux/extra-store/actions';
import { useLazyGetGroupOrderQuery, useLazyGetOrdersQuery } from '../../redux/api';

import { OrderModel } from '../../models/OrderModel';
import { IModifyRouteParams } from './types';


const useMissionOrder = () => {
  const dispatch = useDispatch();
  const { missionId } = useParams<IModifyRouteParams>();

  const [newOrders, setNewOrders] = React.useState<OrderModel[]>([]);
  const [missionOrders, setMissionOrders] = React.useState<OrderModel[]>([]);

  const [ getOrders, { isLoading: isNewLoading, isFetching: isNewFetching } ] = useLazyGetOrdersQuery();
  const [ getGroupOrder, { isLoading: isMissionLoading, isFetching: isMissionFetching } ] = useLazyGetGroupOrderQuery();

  const onDragDropMissionOrder = (dragIdx: number, hoverIdx: number) => {
    setMissionOrders((prev) => {
      const current = [...prev];
      const temp = current[dragIdx];
      current[dragIdx] = current[hoverIdx];
      current[hoverIdx] = temp;
      return current;
    });
  };

  const onToggleOrder = (order: OrderModel, type: 'ADD' | 'REMOVE') => {
    if (type === 'ADD') {
      setMissionOrders((prev) => [...prev, order]);
      setNewOrders((prev) => prev.filter((o) => o.id !== order.id));
      return;
    }
    setNewOrders((prev) => [...prev, order]);
    setMissionOrders((prev) => prev.filter((o) => o.id !== order.id));
  };

  React.useEffect(() => {
    if (missionId) {
      getGroupOrder({ missionId }).unwrap().then((data) =>{
        setMissionOrders(data.orders || []);
        dispatch(setSelectedDrone(data.drone || null));
      });
    }
    getOrders({}).unwrap().then((data) => {
      setNewOrders(data.newOrders || []);
    });
  }, [missionId]);

  return {
    newOrders,
    isNewOrdersLoading: isNewLoading || isNewFetching,

    missionOrders,
    isMissionOrdersLoading: isMissionLoading || isMissionFetching,

    onToggleOrder,
    onDragDropMissionOrder,
  };
};

export default useMissionOrder;