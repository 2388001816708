import React from 'react';
import AddDrone from '../add-drone';
import { Flex, Skeleton, useDisclosure } from '@chakra-ui/react';
import { useDrones } from '@src/features/drones/hooks';
import { RegisterDrone } from '@src/features/drones/components';
import { DroneModel, DroneStatusEnum } from '@src/features/drones/models';
import { EmptyContent } from '@src/components';


const DroneList: React.FC = () => {
  const { drones, isLoading } = useDrones(DroneStatusEnum.NOT_ACTIVE);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [drone, setDrone] = React.useState<DroneModel | null>(null);

  const onSelectDrone = (drone: DroneModel) => {
    setDrone(drone);
    onOpen();
  };

  const onRemoveDrone = () => {
    setDrone(null);
    onClose();
  };

  if (isLoading) {
    return (
      <Flex gap='12px'>
        {[1,2,3,4,5,6].map((idx) => (
          <Skeleton
            key={idx}
            w='calc(20% - 12px)'
            h='100px'
            borderRadius='8px'
            startColor='blackAlpha.300'
            endColor='blackAlpha.900'
          />
        ))}
      </Flex>
    );
  }

  if (drones && drones.length < 1 && !isLoading) {
    return (
      <EmptyContent>
        No drones
      </EmptyContent>
    );
  }
  return (
    <React.Fragment>
      <Flex gap='12px' wrap='wrap'> 
        {drones?.map((drone) => (
          <AddDrone 
            key={drone.id}
            drone={drone}
            w='calc(20% - 12px)'
            onSelectDrone={onSelectDrone} />
        ))}
      </Flex>
      <RegisterDrone key={`key-${drone?.id}`} title='Register Drone' isOpen={isOpen} onClose={onRemoveDrone} drone={drone}  />
    </React.Fragment>
  );
};

export default DroneList;