import React from 'react';
import { DroneIcon } from '@src/components';
import { ZoneIcon } from './assets/zone-sidebar';
import { MaintIcon } from './assets/maint-sidebar';
import { SettingsIcon } from './assets/settings-sidebar';
import { CheckListIcon } from './assets/check-sidebar';


export const sidebarList = [
  {
    id: 1,
    name: 'Drones',
    url: '/app/drones',
    paths: ['/app/drones', '/app/drones/register'],
    icon: (color?: string): JSX.Element => <DroneIcon w='22px' h='21px' iconColor={color} />
  },
  {
    id: 2,
    name: 'Orders',
    url: '/app/orders',
    paths: ['/app/orders', '/app/orders/assignDrone'],
    icon: (color?: string): JSX.Element => <DroneIcon w='22px' h='21px' iconColor={color} />
  },
  {
    id: 3,
    name: 'Zones',
    url: '/app/zones',
    paths: ['/app/zones'],
    icon: (color?: string): JSX.Element => <ZoneIcon  color={color} />
  },
  {
    id: 4,
    name: 'Maint',
    url: '/app/maint',
    paths: ['/app/maint'],
    icon: (color?: string): JSX.Element => <MaintIcon color={color} />,
  },
  {
    id: 5,
    name: 'Check',
    url: '/app/pre-flight-checklist',
    paths: ['/app/pre-flight-checklist'],
    icon: (color?: string): JSX.Element => <CheckListIcon color={color} />
  },
  {
    id: 6,
    name: 'Settings',
    url: '/app/settings',
    paths: ['/app/settings'],
    icon: (color?: string): JSX.Element => <SettingsIcon color={color} />
  },
];