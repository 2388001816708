import React from 'react';
import { Flex, Checkbox, Text } from '@chakra-ui/react';
import { BatteryHealth } from '@src/components';
import { DroneProps } from './types';

const Drone: React.FC<DroneProps> = (props) => {
  const { drone, selectedDrone, onSelect } = props;

  return (
    <Flex
      p='16px'
      gap='8px'
      cursor='pointer'
      direction='column'
      borderRadius='8px'
      border='2px solid'
      borderColor={drone.id === selectedDrone?.id ? 'border.primaryBrand': 'transparent'}
      bg='linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), #121212'
      boxShadow='0px 1px 3px 0px rgba(0, 0, 0, 0.20), 0px 2px 1px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14)'
      onClick={() => onSelect(drone)}> 

      <Flex gap='8px' align='center' fontSize='14px'>
        <Checkbox colorScheme='primaryBrand' isChecked={drone.id === selectedDrone?.id} />
        <Text color='white' fontWeight={700}> {drone?.name} </Text>
        <Text color='txt.secondary'> {drone?.serialNumber} </Text>
      </Flex>

      <Flex gap='8px' align='center' justify='space-between'>
        <Text color='white' fontWeight={700}> {drone?.status} </Text>
        <BatteryHealth percent={drone?.batteryHealth} />
      </Flex>

    </Flex>
  );
};

export default Drone;