
import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { withDefaultLayout } from '@src/layouts';
import { DroneList } from './components';

const AddDronePage: React.FC = () => {
  return (
    <Flex 
      p='32px'
      w='100%' 
      maxW='1440px'
      direction='column'
      m='0 auto'>

      <Text 
        color='white' 
        fontSize='22px' 
        fontWeight={700}> 
        Add Drone
      </Text>

      <Text color='white' mt='18px' mb='12px'> 
        Unregistered drones in your network 
      </Text>

      <DroneList />

    </Flex>
  );
};

export default withDefaultLayout(AddDronePage);