import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@src/store";
import { IGetDroneOrdersResponse } from "../types";

const DroneApi = (state: RootState) => state.droneApi;
const DroneApiState = createSelector([DroneApi], (state) => state);

export const selectDroneOrders = (droneId: string | null) => createSelector([DroneApiState], (apiState) => {
  const queryKey = `getDroneOrders({"droneId":"${droneId}"})`;
  return apiState.queries[queryKey] as { data: IGetDroneOrdersResponse, status: string } | undefined;
}); 


const DroneReducer = (state: RootState) => state.NewDroneReducer;
const DroneState = createSelector([DroneReducer], (state) => state);

export const selectSelectedDrone = createSelector([DroneState], (state) => state.selectedDrone);

