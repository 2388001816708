import React from 'react';
import { Box, BoxProps } from '@chakra-ui/react';


interface OrderWrapperProps extends BoxProps {
  active: boolean;
  children: React.ReactNode;
}

const OrderCardWrapper: React.FC<OrderWrapperProps> = (props) => {
  const { children, active, ...boxProps } = props;

  return (
    <Box 
      p='12px' 
      w='100%' 
      maxW='183px' 
      cursor='pointer' 
      bg='block.alabaster' 
      border='1px solid'
      borderRadius='8px' 
      borderColor={active ? 'border.primary' : 'transparent'}
      {...boxProps}>
      {children}
    </Box>
  );
};

export default OrderCardWrapper;