import React from 'react';
import { Route, Routes } from 'react-router-dom';

import DronesPage from './pages/drones-page';
import AddDronePage from './pages/add-drone-page';


const DroneFeature: React.FC = () => {
  return (
    <Routes>
      <Route path='/' element={<DronesPage />} />
      <Route path='/register' element={<AddDronePage />} />
    </Routes>
  );
};

export default DroneFeature;