import React from 'react';
import { withDefaultLayout } from '@src/layouts';
import { Box } from '@chakra-ui/react';

const ZonesPage: React.FC = () => {
  return (
    <Box />
  );
};

export default withDefaultLayout(ZonesPage);