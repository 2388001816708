export const iceServers = [
  {
    username: 'test',
    credential: 'isteima',
    urls: [
      "turn:172.174.98.158:3478?transport=udp",
      "turn:172.174.98.158:3478?transport=tcp"
    ]
  },
  { urls: 'stun:stun.l.google.com:19302' },
  { urls: 'stun:stun.services.mozilla.com' },

];