import React from 'react';
import { Route, Routes } from 'react-router-dom';
import MaintPage from './pages/maint-page';

const MaintFeature: React.FC = () => {
  return (
    <Routes>
      <Route path='/' element={<MaintPage />} />
    </Routes>
  );
};

export default MaintFeature;