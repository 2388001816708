import React from 'react';
import { useSelector } from 'react-redux';
import { DroneMarker, OrderMarker } from '@src/components';
import { OrderStatusEnum } from '@src/features/orders/models/OrderModel';
import { selectorSelectedGroupOrder, selectSelectedNewOrder } from '@src/features/orders/redux/extra-store/selectors';
import FlighDrone from '../flight-drone';


const MainIndicator: React.FC = () => {
  const newOrder = useSelector(selectSelectedNewOrder);
  const groupOrder = useSelector(selectorSelectedGroupOrder);
    
  if (newOrder) {
    return (
      <OrderMarker 
        status='active'
        position={{ 
          lat: Number(newOrder.deliveryLocation.latitude), 
          lng: Number(newOrder.deliveryLocation.longitude) 
        }}/>
    );
  }

  if (groupOrder && groupOrder.status !== OrderStatusEnum.EN_ROUTE) {
    return (
      <DroneMarker
        status='default'
        position={{ 
          lat: Number(groupOrder?.drone?.latitude), 
          lng: Number(groupOrder?.drone?.longitude) 
        }}  
      />
    );
  }

  if (groupOrder && groupOrder?.status === OrderStatusEnum.EN_ROUTE) {
    return (
      <FlighDrone
        defaultLatitude={Number(groupOrder?.drone?.latitude)} 
        defaultLongitude={Number(groupOrder?.drone?.longitude)} />
    );
  }

  return null;
};

export default MainIndicator;