import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { PopupWrapper } from '../components';
import { useSelector } from 'react-redux';
import OrderPopupActions from './new-order-popup-actions';
import { selectSelectedNewOrder } from '@src/features/orders/redux/extra-store/selectors';


const NewOrderPopup: React.FC = () => {

  const selected = useSelector(selectSelectedNewOrder);

  return (
    <PopupWrapper 
      title={`Order #${selected?.id}`}>
        
      <Flex 
        my='16px'
        gap='8px'
        direction='column'

        fontSize='12px'
        color='text.snow'>

        <Flex gap='4px' align='flex-start' justify='space-between'>
          <Text> Destination </Text>
          <Text textAlign='right'> {selected?.deliveryLocation.address}</Text>
        </Flex>

        <Flex gap='4px' align='flex-start' justify='space-between'>
          <Text> Method </Text>
          <Text textAlign='right'> {selected?.deliveryMethod} </Text>
        </Flex>

        <Flex gap='4px' align='flex-start' justify='space-between'>
          <Text> Client Name </Text>
          <Text textAlign='right'> {selected?.clientName} </Text>
        </Flex>

        <Flex gap='4px' align='flex-start' justify='space-between'>
          <Text> Content </Text>
          <Text textAlign='right'> {selected?.packageContent} </Text>
        </Flex>

      </Flex>

      {selected && <OrderPopupActions order={selected}/>}

    </PopupWrapper>
  );
};

export default NewOrderPopup;