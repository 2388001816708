import createSagaMiddleWare from 'redux-saga';
import { configureStore } from '@reduxjs/toolkit';
import { rootReducer } from './rootReducer';
import  rootSaga from './rootSaga';
import { authApi } from '@src/features/auth/redux/api';
import { settingApi } from '@src/features/settings/redux/api';
import { droneApi } from '@src/features/drones/redux/api';
import { orderApi } from '@src/features/orders/redux/api';


const sagaMiddleWare = createSagaMiddleWare();

const middlewares = [
  sagaMiddleWare, 
  authApi.middleware,
  droneApi.middleware,
  orderApi.middleware,
  settingApi.middleware,
];

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middlewares),
});

sagaMiddleWare.run(rootSaga);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export { store };