
export enum LoadingStatus {
	ERROR = 'ERROR',
	NEVER = 'NEVER',
	LOADED = 'LOADED',
	SUCCESS = 'SUCCESS',
	LOADING = 'LOADING',
}

export enum PolyLineStatus {
	HISTORY = 'HISTORY',
	FUTURE = 'FUTURE',
	ERROR = 'ERROR',
	RETURN = 'RETURN'
}


export enum MarkerStatus {
	DEFAULT = 'DEFAULT',
	ACTIVE = 'ACTIVE',
	ERROR = 'ERROR',
}