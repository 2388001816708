import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@src/store';
import { IOrderInitialState } from '../types';
import { OrderStatusEnum } from '../../models/OrderModel';

const OrderReducer = (state: RootState): IOrderInitialState => state.OrderReducer;
const OrderState = createSelector([OrderReducer], (state) => state);

export const selectorCollapsed = createSelector([OrderState], (state) => state.collapsed);

export const selectSelectedNewOrder = createSelector([OrderState], (state) => state.selectedNewOrder);

export const selectorSelectedGroupOrder = createSelector([OrderState], (state) => state.selectedGroupOrder);

export const selectorFormattedAdress = createSelector([OrderState], (state) => state.formattedAddress);

export const selectorPopup = createSelector([OrderState], (state) => {
  if (state.selectedNewOrder) {
    return OrderStatusEnum.NEW;
  }

  if (state.selectedGroupOrder) {
    return state.selectedGroupOrder.status;
  }

  return null;
});