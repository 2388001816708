import React from 'react';
import { Route, Routes } from 'react-router-dom';

import OrdersPage from './pages/orders-page';
import AssignDronePage from './pages/assign-drone-page';
import CreateOrderPage from './pages/create-order-page';
import UpdateOrderPage from './pages/update-order-page';
import ModifyRoutePage from './pages/modify-route-page';


const OrderFeature: React.FC = () => {
  return (
    <Routes>
      <Route path='/' element={<OrdersPage/>} />
      <Route path='/create' element={<CreateOrderPage/>} />
      <Route path='/update/:orderId' element={<UpdateOrderPage/>} />
      <Route path='/assignDrone' element={<AssignDronePage/>} />
      <Route path='/modifyRoute/:missionId' element={<ModifyRoutePage />}/>
    </Routes>
  );
};

export default OrderFeature;