import React from 'react';
import { Button, Flex } from '@chakra-ui/react';
import { Collapse } from '../components';
import { useNavigate } from 'react-router-dom';
import NewOrderItem from './new-order-item';
import { OrderStatusEnum } from '@src/features/orders/models/OrderModel';
import { useGetOrdersQuery } from '@src/features/orders/redux/api';


const NewOrdersList: React.FC = () => {

  const navigate = useNavigate();
  const { data } = useGetOrdersQuery({ });


  const redirectToAssignDrone = (): void => navigate('/app/orders/assignDrone');

  return (
    <Collapse type={OrderStatusEnum.NEW} count={data?.newOrders?.length || 0}>
      <Flex p='12px' flexDirection='column'>
        <Button variant='brand' onClick={redirectToAssignDrone}> Assign Drone </Button>
      </Flex>
      <Flex gap='10px' flexWrap='wrap'>
        {data?.newOrders?.map((order) => (
          <NewOrderItem 
            key={order.id} 
            order={order} />
        ))}
      </Flex>
    </Collapse>
  );
};

export default NewOrdersList;