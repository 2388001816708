import React from 'react';
import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';
import { IconButton, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { useSearchParams } from 'react-router-dom';
import { selectDroneOrders } from '@src/features/drones/redux/extra-store/selectors';
import { DownloadIcon } from '@chakra-ui/icons';
import { secondsToMinutes } from '@src/features/drones/shared';
import { EmptyContent } from '@src/components';


const DroneOrdersHistory: React.FC = () => {
  const [searchParams] = useSearchParams();
  const orders = useSelector(selectDroneOrders(searchParams.get('droneId')));

  if (!orders?.data && orders?.status === 'pending') {
    return null;
  }
 
  
  if (orders?.data && orders?.data?.orders?.length < 1) {
    return (
      <EmptyContent>
        No order history
      </EmptyContent>
    );
  }
  return (
    <TableContainer p='12px'>
      <Table variant='unstyled'>
        <Thead>
          <Tr color='white'>
            <Th> Time </Th>
            <Th> FLight Time </Th>
            <Th> Distance </Th>
            <Th> Order # </Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {orders?.data?.orders?.map((order) => {
            const file = order?.filename?.split('.')[0];
            return (
              <Tr 
                key={order.id} 
                color='white'
                fontSize='14px'>
                <Td> {DateTime.fromISO(order.createDate).toLocaleString(DateTime.DATE_MED)} </Td>
                <Td> {secondsToMinutes(order.totalFlightTime || 0)} </Td>
                <Td> {order.totalDistance || 0} m </Td>
                <Td> {file 
                  ? <a
                    target='_blank'
                    rel="noreferrer"
                    style={{ color: 'white', textDecoration: 'underline' }}
                    href={`https://api.asend.io/plot_app?log=${file}`}> {order.id} </a>
                  : order.id } 
                </Td>
                <Td> 
                  <IconButton 
                    icon={<DownloadIcon />}
                    aria-label='download-icon'
                  /> 
                </Td>
              </Tr>
            );})}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default DroneOrdersHistory;